import type { ComponentType } from 'react'
import { v4 as uuidkey } from 'uuid'

import BannerText from 'src/components/sections/BannerText'
import Hero from 'src/components/sections/Hero'
import IncentivesHeader from 'src/components/sections/Incentives/IncentivesHeader'
import ProductShelf from 'src/components/sections/ProductShelf'
import ProductTiles from 'src/components/sections/ProductTiles'

import { BannerCarrosselRich } from '../sections/BannerCarrosselRich'
import { ShelfRich } from '../sections/Shelf/shelfRich'
import { NewsLetterEpoca as NewsLetter } from '../sections/NewsLetterEpoca'
import { ShelfPercycle } from '../sections/Shelf/shelfPercycle'
import { ShelfVtex } from '../sections/Shelf/shelfVtex'
import { Mosaico } from '../sections/Mosaico'
import { BannerCarrosselVtex } from '../sections/BannerCarrosselVtex'
import { Conteudo } from '../sections/Conteudo'
import { PiscouPerdeu } from '../sections/PiscouPerdeu'
import { SacMarcas } from '../sections/SacMarcas'
import { HeaderComBanner } from '../sections/HeaderComBanner'
import { Cupons } from '../sections/Cupons'
import { ListagemDeProdutos } from '../sections/ListagemDeProdutos'
import { BoxDermaclubPDP } from '../sections/BoxDermaclubPDP'
import { ColecoesMarcas } from '../sections/ColecoesMarcas'
import { ImagemEDescricao } from '../sections/ImagemEDescricao'
import { LancamentoEDestaque } from '../sections/LancamentoEDestaque'
import { MosaicoMarca } from '../sections/MosaicoMarca'
import { MenuSidebar } from '../sections/MenuSidebar'
import { CategoriaCarrossel } from '../sections/CategoriaCarrossel'
import { BoxContent } from '../sections/BoxContent'
import { TextCallToAction } from '../sections/TextCallToAction'
import { ImagemSingle } from '../sections/ImagemSingle'
import { BannerCards } from '../sections/BannerCards'
import { YoutubeVideo } from '../sections/YoutubeVideo'

/**
 * Sections: Components imported from '../components/sections' only.
 * Do not import or render components from any other folder in here.
 */
const COMPONENTS: Record<string, ComponentType<any>> = {
  Hero,
  BannerText,
  IncentivesHeader,
  ProductShelf,
  ProductTiles,
  BannerCarrosselRich,
  ShelfRich,
  NewsLetter,
  ShelfPercycle,
  ShelfVtex,
  Mosaico,
  BannerCarrosselVtex,
  Conteudo,
  PiscouPerdeu,
  SacMarcas,
  HeaderComBanner,
  Cupons,
  ListagemDeProdutos,
  BoxDermaclubPDP,
  ColecoesMarcas,
  ImagemEDescricao,
  LancamentoEDestaque,
  MosaicoMarca,
  MenuSidebar,
  CategoriaCarrossel,
  BoxContent,
  TextCallToAction,
  ImagemSingle,
  BannerCards,
  YoutubeVideo,
}

interface Props {
  sections?: Array<{ name: string; data: unknown }>
  pdp?: boolean
  gtin?: string
}

function RenderPageSections({ sections, pdp, gtin }: Props) {
  return (
    <>
      {sections?.map(({ name, data }, index) => {
        const Component = COMPONENTS[name]

        if (Component) {
          return (
            <Component
              key={`cms-section-${uuidkey()}`}
              index={index}
              {...(data as any)}
              pdp={pdp}
              gtin={gtin}
            />
          )
        }

        console.info(
          `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
        )

        return false
      })}
    </>
  )
}

export default RenderPageSections
