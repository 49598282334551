import { Badge } from '@faststore/ui'
import { useDiscountPercent } from 'src/sdk/product/useDiscountPercent'

type Props = {
  listPrice: number
  spotPrice: number
}

const DiscountBadge = ({ listPrice, spotPrice }: Props) => {
  const discountPercent = useDiscountPercent(listPrice, spotPrice)

  if (!Number(discountPercent)) {
    return <></>
  }

  const discount = discountPercent.toString().split(',')

  return <Badge className="text-sm">{discount}%</Badge>
}

export default DiscountBadge
