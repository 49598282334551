import copy from 'copy-to-clipboard'
import Link from 'next/link'
import { useState } from 'react'

import { CupomTooltip } from './CupomTooltip'

interface CuponsProps {
  allItems: AllItemsProps[]
}

interface AllItemsProps {
  ativo: boolean
  tipo: string
  nomeLink: string
  link?: string
  nome: string
  validade?: string
  regra: string
}

export const Cupons = (props: CuponsProps) => {
  const { allItems } = props
  const [copied, setCopied] = useState('')
  const copyToClipboard = (cupom: string) => {
    copy(cupom)
    setCopied(cupom)
  }

  return (
    <section className="container h-auto w-full" data-testid="section-cupom">
      {allItems.map((items, key) => {
        return (
          <>
            {items.ativo && (
              <div
                className="mx-[20px] h-auto mb-[20px] relative"
                style={{
                  border: '1px solid #999',
                }}
                data-testid="card-cupom"
                key={key}
              >
                <div className="bg-[#f597a3] h-full absolute flex text-center">
                  <div
                    style={{
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                    className=" text-white w-full uppercase leading-[50px] font-medium"
                  >
                    {items.tipo}
                  </div>
                </div>

                <div className="pl-[70px] py-[20px] pr-[10px]">
                  <div className="flex flex-col md:flex-row justify-between text-[#000]">
                    <Link href={items.link ? items.link : '#'}>
                      <a className="text-[24px] underline font-semibold">
                        <span>{items.nomeLink}</span>
                      </a>
                    </Link>
                    {items.validade && (
                      <span className="text-xs my-2 md:my-0 whitespace-nowrap">
                        válido até {items.validade}
                      </span>
                    )}
                  </div>
                  <div className="mt-1 md:mt-[15px] mb-[15px] flex flex-col sm:flex-row">
                    <span className="text-xs mr-[15px] font-medium my-auto">
                      Use o cupom:
                    </span>
                    <div
                      className="tracking-wider p-[15px] mt-1 sm:mt-0 text-center"
                      style={{ border: '1px dashed #f597a3' }}
                    >
                      <span
                        onClick={() => copyToClipboard(items.nome)}
                        onKeyPress={() => copyToClipboard(items.nome)}
                        role="button"
                        tabIndex={0}
                        className="uppercase font-semibold text-black"
                      >
                        {items.nome}
                        <CupomTooltip
                          copied={copied}
                          nameItem={items.nome}
                          setCopied={setCopied}
                        />
                      </span>
                    </div>
                  </div>
                  <span className="text-xs pt-[10px]">{items.regra}</span>
                </div>
              </div>
            )}
          </>
        )
      })}
    </section>
  )
}
