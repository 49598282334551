import { useRef } from 'react'

import { Image } from '../../ui/Image'

interface BannerRichItemProps {
  placement: string
  urlClick: string
  textoAlt: string
  imgDesk: string
  imgMobile: string
  fullBanner: boolean
  aspectRatio: number
  eager?: boolean
}

export const BannerRichItem = ({
  placement,
  urlClick,
  textoAlt,
  imgDesk,
  imgMobile,
  fullBanner,
  aspectRatio = 1,
  eager,
}: BannerRichItemProps) => {
  const classes = !fullBanner
    ? 'lg:last:col-span-1 md:last:col-span-4 last:col-span-2'
    : ''

  const altImg = textoAlt ?? imgMobile.split('/').pop()

  const ref = useRef<HTMLDivElement>(null)

  return (
    <>
      <div className={`BannerRichItem ${classes}`} ref={ref}>
        <a
          key={placement}
          href={urlClick ?? '#'}
          title={altImg}
          tabIndex={-1}
          className="block"
        >
          <Image
            preload
            alt={altImg}
            src={imgDesk}
            srcSet={`${imgMobile} 300w, ${imgDesk} 1000w`}
            width={1280}
            height={360 / aspectRatio}
            sizes="(max-width: 768px) 25vw, 30vw"
            className="h-full"
            data-testid="imgBanner"
            data-fs-image
            fetchpriority="high"
            loading={eager ? 'eager' : 'lazy'}
          />
        </a>
      </div>
    </>
  )
}
