import { Badge, Label as UILabel, List as UIList } from '@faststore/ui'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import type { FilterProps, OnFacetChange } from './Facets'
import Checkbox from 'src/components/ui/Checkbox'
import { useState } from 'react'
import styles from './filterbrand.module.scss'

export interface FilterBrandsProps {
  values: FilterProps[]
  onFacetChange: OnFacetChange
  testId: string
  label: string
  Brandkey: string
}

export const FilterBrands = ({
  values,
  onFacetChange,
  testId,
  label,
  Brandkey,
}: FilterBrandsProps) => {
  const [brandTerm, setBrandTerm] = useState('')

  const _filterBrands = (value: string) => {
    setBrandTerm(value)
  }

  return (
    <>
      <div className={styles.filterbrand}>
        <input
          data-input-brand
          type="text"
          placeholder="Buscar Marca"
          onChange={(e) => _filterBrands(e.target.value)}
        />
        <MagnifyingGlassIcon data-icon-search />
      </div>
      <div className={styles.limit_height_brands}>
        <UIList data-fs-facets-list>
          {values
            .filter((val: any) => {
              if (brandTerm !== '') {
                return val.value.includes(brandTerm)
              }

              return val.value
            })
            .map((item) => {
              const id = `${testId}-${label}-${item.label}`

              return (
                <li key={id} data-fs-facets-list-item>
                  <Checkbox
                    id={id}
                    checked={item.selected}
                    onChange={() =>
                      onFacetChange(
                        { key: Brandkey, value: item.value },
                        'BOOLEAN'
                      )
                    }
                    data-fs-facets-list-item-checkbox
                    data-testid={`${testId}-accordion-panel-checkbox`}
                    data-value={item.value}
                    data-quantity={item.quantity}
                  />
                  <UILabel
                    htmlFor={id}
                    className="text__title-mini-alt"
                    data-fs-facets-list-item-label
                  >
                    {item.label}{' '}
                    <Badge data-fs-facets-list-item-badge>
                      {item.quantity}
                    </Badge>
                  </UILabel>
                </li>
              )
            })}
        </UIList>
      </div>
    </>
  )
}
