import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { order } from 'src/utils'

import type { SacMarcasProps, DadosMarcaProps, MarcaProps } from './SacMarcas'
import { DadosMarcaModal } from './DadosMarcaModal'

export const SacMarcas = ({ allItems }: SacMarcasProps) => {
  const [dadosDaMarca, setDadosDaMarca] = useState<DadosMarcaProps | any>()
  const [visible, setVisible] = useState(false)
  const [filteredItems, setFilteredItems] = useState(allItems)

  const dadosModal = (dadosMarca: any) => {
    setDadosDaMarca(dadosMarca)
    setVisible(true)
  }

  const search = (event: React.FormEvent<HTMLInputElement>) => {
    const text = event.currentTarget.value

    if (text) {
      setFilteredItems(
        allItems
          .map((allItem) => {
            const dadosMarca = allItem.dadosMarca.filter((marca) =>
              marca.nome.toLowerCase().includes(text.toLowerCase())
            )

            if (dadosMarca.length) {
              return { ...allItem, dadosMarca }
            }

            return null
          })
          .filter(Boolean) as MarcaProps[]
      )
    } else {
      setFilteredItems(allItems)
    }
  }

  return (
    <div className="lg:pl-[20px] lg:pr-[20px] pl-2 pr-2">
      <form
        className="
            flex-1 relative
            border-primary-200
            md:w-[400px] xs:w-[350px] w-[305px] order-last mt-2 xl:order-none mb-2
          "
      >
        <input
          type="text"
          data-testid="input-marcas"
          placeholder="Oi, qual marca você procura hoje? :)"
          style={{ border: '2px solid #000' }}
          className="rounded-[3px] bg-gray-light px-2 py-1 w-full relative"
          onChange={search}
        />
        <button
          type="button"
          className="right-0 top-0 absolute h-40 w-40 flex items-center justify-center"
          tabIndex={0}
          aria-label="Pesquisar"
        >
          <MagnifyingGlassIcon className="w-[24px] text-primary-500" />
        </button>
      </form>
      <div>
        {/* pegando os itens filtrados */}
        {[...filteredItems].sort(order).map((marca, index) => {
          return (
            <>
              <p
                className="md:text-[24px] font-bold mb-3 mt-3 text-[20px]"
                style={{ borderBottom: '1px solid #000' }}
                key={index}
              >
                {marca.nome.toUpperCase()}
              </p>
              <ul className="mdMax:flex mdMax:justify-start mdMax:flex-wrap">
                {[...marca.dadosMarca]
                  .sort(order)
                  .map((nomeMarca: any, key: number) => {
                    return (
                      <li
                        key={key}
                        className="md:leading-[46px] w-[50%] md:w-[25%] md:text-base text-sm md:inline-block md:flex-col leading-6"
                      >
                        <button
                          onClick={() => dadosModal(nomeMarca)}
                          data-testid="open-modal-sac"
                        >
                          {nomeMarca.nome}
                        </button>
                      </li>
                    )
                  })}
              </ul>
            </>
          )
        })}
        <ul />
      </div>
      <DadosMarcaModal
        dadosMarca={dadosDaMarca}
        visible={visible}
        close={() => {
          setVisible(false)
        }}
      />
    </div>
  )
}
