import { useMemo } from 'react'
import slugify from 'slugify'
import { usePriceFormatter } from 'src/sdk/product/useFormattedPrice'
import type { MktData } from '../index'
import { getCookie } from '../index'

interface breadcrumbProductBuildProps {
  categorieSlug: string
  slug: string
  name: string
}

export function breadcrumbProductBuild({
  categorieSlug,
  slug,
  name,
}: breadcrumbProductBuildProps) {
  const itemListElement: any[] = []

  if (!categorieSlug) {
    return []
  }

  const categories = categorieSlug.split('/').filter((item) => item)

  categories.forEach((category: string, key: number) => {
    itemListElement.push({
      item: `/${slugify(category)}`,
      name: category,
      position: key,
    })
  })

  itemListElement.push({
    item: `${slug}/p`,
    name,
    position: categories.length + 1,
  })

  return itemListElement
}

export function slugifyBrand(brand: string) {
  // filtro os caracteres especiais que quero que fique com  '-' no lugar
  let string = brand.replace('&', '-')

  string = string.replace(/^\s+|\s+$/g, '') // remove os caracteres especiais
  string = string.toLowerCase()

  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'

  for (let i = 0, l = from.length; i < l; i++) {
    // subistitui o 'from' pelo 'to'
    string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  string = string
    .replace(/[^a-z0-9 -]/g, '-') // aplica o replace para o que não é letras nem numeros
    .replace(/\s+/g, '-') // onde houver espaço em branco vai colocar um -

  return string
}

export const useCustomFormattedPrice = (price: number) => {
  const formatter = usePriceFormatter({ decimals: true })

  return useMemo(() => formatter(price), [formatter, price])
}

export const calculateDiscount = (price: number, listPrice: number) => {
  return Math.round(100 - (price / listPrice) * 100)
}

export const requestCartSimulator = async (obj: any) => {
  const data = JSON.stringify(obj)

  const request = await fetch(
    '/api/vtex/api/checkout/pub/orderforms/simulation',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    }
  )

  const requestJson = await request.json()

  return requestJson
}

export interface SlaShippingProps {
  name: string
  price: number
  shippingEstimate: string
}

export interface simulateCartShipptingProps {
  slas: SlaShippingProps[]
  erro: {
    code: string
    message: string
  }
}

export const simulateCartShippting = async (obj: any) => {
  const data = await requestCartSimulator(obj)

  const responseData: SlaShippingProps[] = []

  const slas = data.logisticsInfo ? data.logisticsInfo[0].slas : []

  slas.forEach((sla: any) => {
    responseData.push({
      name: sla.name,
      price: sla.price,
      shippingEstimate: sla.shippingEstimate,
    })
  })

  return {
    slas: responseData,
    erro: data?.error,
  }
}

export const searchProductBySkus = async (skuIds: string[]) => {
  // fq=skuId:29074&
  const query = skuIds.map((sku) => {
    return `fq=skuId:${sku}`
  })

  const queryStr = query.join('&')

  if (queryStr) {
    const url = `/api/vtex/search?${queryStr}`

    const requestGifts = await fetch(url)

    const requestGiftsJson = await requestGifts.json()

    const giftsToReturn = requestGiftsJson.map((gift: any) => {
      const itemFiltred = gift.items
        .filter((i: any) => {
          return skuIds.includes(i.itemId)
        })
        .pop()

      const imageId = itemFiltred.images[0].imageId
      const image = itemFiltred.images[0].imageUrl.replace(
        `/${imageId}/`,
        `/${imageId}-120-120/`
      )

      return {
        brand: gift.brand,
        name: gift.productName,
        image,
      }
    })

    return giftsToReturn
  }

  return []
}

export const checkCondicionalsPDP = (
  ckeckKey: string,
  ckeckValue: string[],
  product: any,
  skuSelecionado: any
) => {
  const clustersIds = Object.keys(product?.productClusters)
  const ckeckValueTrim = ckeckValue.map((keyItem: string) => keyItem.trim())

  const lengthDep = product?.categoriesIds.length - 1
  const DepartamentoId = product?.categoriesIds
    ? product?.categoriesIds[lengthDep].replaceAll('/', '')
    : ''

  switch (ckeckKey) {
    case 'all':
      return true

    case 'colecao':
      return ckeckValueTrim.some((item) => clustersIds.includes(item))

    case 'departamento':
      return ckeckValueTrim.includes(String(DepartamentoId))

    case 'categoria':
      return ckeckValueTrim.includes(String(product?.categoryId))

    case 'marca':
      return ckeckValueTrim.includes(String(product?.brandId))

    case 'produtoId':
      return ckeckValueTrim.includes(String(product?.productId))

    case 'sku':
      return ckeckValueTrim.includes(String(skuSelecionado))

    default:
      return false
  }
}

export const dermaClubMDInfo = async (ean: string) => {
  const url = `/api/vtex/api/dataentities/DP/search?_fields=nominal_discount_value,percentage_reward_value,sku_id,price&gtin=${ean}`

  const request = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.vtex.ds.v10+json',
    },
  })

  const data = await request.json()

  return data.pop()
}

export interface dermaSearchBuyerProps {
  data: {
    gtin: string
    email: string
    tax_document_number: string
  }
}

export const dermaSearchBuyer = async ({ data }: dermaSearchBuyerProps) => {
  const baseUrl = `https://api-manager.zicardapi.com.br/epoca/v1/loreal/channels/epocacosmeticos/campaigns/dermaclub`
  const url = `${baseUrl}/buyers/search`

  const request = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return request.json()
}

export const getPricesPromotionals = async (product: any, mktData: MktData) => {
  const itemsProducts = product.items
  const itemsToRetun: any[] = []
  const promises: any[] = []

  itemsProducts.forEach((item: any) => {
    const obj = {
      items: [
        {
          id: item.itemId,
          quantity: 1,
          seller: item.sellers[0].sellerId,
        },
      ],
      country: 'BRA',
      marketingData: mktData,
    }

    promises.push(requestCartSimulator(obj))
  })

  const resolutions = await Promise.allSettled(promises)

  resolutions.forEach((resp: any) => {
    const cartItem = resp.value

    itemsToRetun.push({
      sku: cartItem.items[0].id,
      price: cartItem.items[0].sellingPrice / 100,
      listPrice: cartItem.items[0].listPrice / 100,
      installments: cartItem.paymentData.installmentOptions,
    })
  })

  return itemsToRetun
}

export const getCookiesMktData = () => {
  const MktData: MktData = {}

  const utmSource = getCookie('epc_utm_source')

  if (utmSource) {
    MktData.utmSource = utmSource
  }

  const utmCampaign = getCookie('epc_utm_campaign')

  if (utmCampaign) {
    MktData.utmCampaign = utmCampaign
  }

  const utmMedium = getCookie('epc_utm_medium')

  if (utmMedium) {
    MktData.utmMedium = utmMedium
  }

  const utmiPart = getCookie('epc_utmi_part')

  if (utmiPart) {
    MktData.utmiPart = utmiPart
  }

  const utmipage = getCookie('epc_utmi_page')

  if (utmipage) {
    MktData.utmiPage = utmipage
  }

  const utmiCampaign = getCookie('epc_utmi_campaign')

  if (utmiCampaign) {
    MktData.utmiCampaign = utmiCampaign
  }

  const coupon = getCookie('epc_coupon')

  if (coupon) {
    MktData.coupon = coupon
  }

  return MktData
}

export const getPromotionalPriceOfSku = (prices: any[], sku: string) => {
  const filtered = prices.filter((item) => {
    return item.sku === sku
  })

  return filtered[0]
}

export const getInstallmentsPromotional = (installments: any[]) => {
  if (!installments) {
    return null
  }

  const intallmentsVisa = installments.filter(
    (inst) => inst.paymentName === 'Visa'
  )[0]

  const realInstallments = intallmentsVisa.installments

  const bestInstallment =
    realInstallments && realInstallments.length > 0
      ? [...realInstallments].sort((a, b) => b.count - a.count).shift()
      : null

  if (!bestInstallment) {
    return null
  }

  return {
    parcelas: bestInstallment.count,
    valorParcela: bestInstallment.value / 100,
  }
}
