import type { FormEvent, MouseEvent, KeyboardEvent } from 'react'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useCart } from 'src/sdk/cart'
import { addEmailToOrderform } from 'src/utils'
import { dermaClubMDInfo, dermaSearchBuyer } from 'src/utils/Product'
import styles from './box-dermaclub.module.scss'

export interface FormDermaclubProps {
  gtin: string
}

export const FormDermaclub = ({ gtin }: FormDermaclubProps) => {
  const [cookies, setCookie] = useCookies()
  const persistCpf = cookies.cpfDermaclub ?? ''
  const persistEmail = cookies.emailDermaclub ?? ''
  const persistDermaclubActive = cookies.dermaclubActive ?? ''

  const [erros, setErros] = useState('')
  const [email, setEmail] = useState(persistEmail)
  const [cpf, setCPF] = useState(persistCpf)
  const [dermaclubActive] = useState(persistDermaclubActive)
  const [updateOrderForm, setUpdateOrderForm] = useState(0)
  const [cadastroSuccess, setCadastroSuccess] = useState(false)
  const [prefixText, setPrefixText] = useState('Na compra desse produto,')
  const [complementText, setComplementText] = useState(
    'A CADA R$ 1 = 10 PONTOS'
  )

  const cart = useCart()

  const expireTime = 48 * 60 * 60 * 1000

  const handlerCPFChange = (cpfNumber: string) => {
    const cleanValue = cpfNumber.replace(/\.|-/gm, '')

    setCPF(cleanValue)

    const expires = new Date()

    expires.setTime(expires.getTime() + expireTime)

    setCookie('cpfDermaclub', cleanValue, { path: '/', expires })
  }

  const handlerEmailChange = (emailText: string) => {
    setEmail(emailText)

    const expires = new Date()

    expires.setTime(expires.getTime() + expireTime)

    setCookie('emailDermaclub', emailText, { path: '/', expires })
  }

  // prettier-ignore
  const handlerSubmit = (e: FormEvent<HTMLFormElement>) => {// NOSONAR
    e.preventDefault()
    let noErros = false

    if (email === '' && cpf === '') {
      setErros('O email e o CPF precisam ser preenchidos.')
    } else if (cpf === '') {
      setErros('O CPF precisa ser preenchido.')
    } else if (email === '') {
      setErros('O email precisa ser preenchido.')
    } else {
      setErros('')
      noErros = true
    }

    const data = {
      gtin,
      email,
      tax_document_number: cpf,
    }

    if (noErros) {
      dermaSearchBuyer({ data }).then((response) => {
        const dermaclub = response.dermaclub

        dermaClubMDInfo(gtin).then((responseData) => {
          if (responseData) {
            if (responseData.nominal_discount_value === 0) {
              setPrefixText('Na compra desse produto,')
              setComplementText('A CADA R$ 1 = 10 PONTOS')
            } else {
              const price = (responseData.price / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })

              setPrefixText('Você desbloqueou o novo preço')
              setComplementText(price)
            }
          }

          if (dermaclub) {
            setCadastroSuccess(true)
            setUpdateOrderForm(updateOrderForm + 1)
          }
        })
      })
    }
  }

  const handlerCadastreSe = (
    e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLSpanElement>
  ) => {
    e.preventDefault()
    const cadastroURL = 'https://clube.dermaclub.com.br/cadastro-ecommerce'

    if (window) {
      const t = (window.screen.height - 712) / 2
      const r = (window.screen.width - 550) / 2

      const _open = window.open(
        cadastroURL,
        'Cadastro',
        `width=550,height=712,location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=no,top=${t},left=${r}`
      )

      if (_open) {
        _open.focus()

        setTimeout(function () {
          _open.postMessage(
            {
              parceiroDermaUrl: 'https://www.epocacosmeticos.com.br',
            },
            cadastroURL
          )
        }, 1e3)
      }
    }
  }

  useEffect(() => {
    dermaClubMDInfo(gtin).then((responseData) => {
      if (responseData) {
        if (responseData.nominal_discount_value === 0) {
          setPrefixText('Na compra desse produto,')
          setComplementText('A CADA R$ 1 = 10 PONTOS')
        } else {
          const price = (responseData.price / 100).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })

          setPrefixText('Na compra desse produto, o preço fica em ')
          setComplementText(price)
        }
      }
    })
  }, [gtin])

  useEffect(() => {
    if (email && !dermaclubActive) {
      addEmailToOrderform(email, cart.id)

      const expires = new Date()

      expires.setTime(expires.getTime() + expireTime)

      setCookie('dermaclubActive', 'true', { path: '/', expires })
    }
  }, [dermaclubActive, updateOrderForm])

  useEffect(() => {
    const dermaclubPendentCluster = cookies._dermaclubPendentCluster

    if (dermaclubPendentCluster && cart.id) {
      addEmailToOrderform(dermaclubPendentCluster, cart.id)
    }
  }, [cart.id])

  return (
    <div className={styles.FormDermaclub}>
      <div data-header>
        {prefixText} <strong>{complementText}</strong>
      </div>

      {cadastroSuccess ? (
        <div data-menssage-success>
          <p>Cadastro validado com sucesso.</p>
        </div>
      ) : (
        <form
          onSubmit={(e) => handlerSubmit(e)}
          id="clickspaceDermaclubForm"
          className="clickspace-dermaclub-form"
        >
          <input
            type="email"
            id="clickspaceDermaclubInpEmail"
            className="clickspace-dermaclub-input"
            placeholder="E-mail"
            onChange={(e) => handlerEmailChange(e.target.value)}
            value={email}
          />
          <input
            type="text"
            id="clickspaceDermaclubInpDocument"
            className="clickspace-dermaclub-input"
            placeholder="CPF"
            onChange={(e) => handlerCPFChange(e.target.value)}
            value={cpf}
          />
          <input
            type="submit"
            id="clickspaceDermaclubButton"
            className="clickspace-dermaclub-button"
            value="ATIVAR"
          />
        </form>
      )}

      <div data-erros>{erros}</div>
      <div data-footer>
        <small>
          Não acumulativo com outros cupons de desconto (verifique a finalização
          da compra)
        </small>
        {!cadastroSuccess && (
          <button
            type="button"
            data-cadastre-se
            onKeyUp={(e) => handlerCadastreSe(e)}
            onClick={(e) => handlerCadastreSe(e)}
          >
            Não é cadastrado? Cadastre-se
          </button>
        )}
      </div>
    </div>
  )
}
