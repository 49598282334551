import { useEffect, useState } from 'react'
import { BannerLoader } from './BannerLoader'
import { BannerRichItem } from './BannerRichItem'

interface BannerWithoutCarrosselProps {
  dots: boolean
  slides: number
  bannerInfo: any
  initialPlacement: boolean
  index?: number
}

export const BannerWithoutCarrossel = ({
  initialPlacement,
  bannerInfo,
  index,
}: BannerWithoutCarrosselProps) => {
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (bannerInfo.length > 0) {
      setIsLoad(true)
    }
  }, [bannerInfo])

  return (
    <>
      {isLoad ? (
        <section className="container relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-1 px-2 py-2 mb-2">
          {bannerInfo?.map((item: any, key: number) => {
            const {
              creatives: [creatives],
            } = item

            // diz se a imagem vai carregar como 'eager' ou 'lazy'
            const isEager = !!(index !== undefined && index <= 1)

            return (
              <BannerRichItem
                key={key}
                placement={item.placement}
                urlClick={creatives.URL_CLICK}
                textoAlt={creatives.TEXT}
                imgDesk={creatives.URL_IMAGEM}
                imgMobile={creatives.URL_IMAGEM_MOBILE}
                fullBanner={initialPlacement}
                aspectRatio={1.5}
                eager={isEager}
              />
            )
          })}
        </section>
      ) : (
        <BannerLoader fullbanner={false} />
      )}
    </>
  )
}
