import styles from '../styles/ppshelf.module.scss'

export const ProdutoEsgotado = () => {
  // card para caso o pedido esteja esgotado
  return (
    <div className={styles.wrapper_esgotado}>
      <div className={styles.esgotado_text}>Produto esgotado :(</div>
      <div className={styles.esgotado_text}>
        Todos os dias ofertas incríveis pra você
      </div>
    </div>
  )
}
