import styles from './bannerCards.module.scss'

interface BannerCardsProps {
  nome: string
  allItems: Array<{
    imagem: string
    href: string
  }>
}

export const BannerCards = ({ allItems, nome }: BannerCardsProps) => {
  return (
    <section className={styles.bannerCards}>
      <h3>{nome}</h3>
      <div>
        <div className={styles.conteudo}>
          {allItems.map((item, key) => {
            return (
              <div className={styles.cards} key={key}>
                <a href={item.href}>
                  <img className={styles.image} src={item.imagem} />
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
