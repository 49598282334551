import { useState } from 'react'
import styles from './youtubeVideo.module.scss'

interface YoutubeVideoProps {
  titulo: string
  imagemDesk: string
  imagemMobile: string
  codigo: string
}

export const YoutubeVideo = ({
  codigo,
  imagemDesk,
  imagemMobile,
  titulo,
}: YoutubeVideoProps) => {
  const [enable, setEnabled] = useState(false)

  return (
    <section className={styles.youtubeVideo}>
      <h3>{titulo}</h3>
      <div className={styles.contentVideo} role="presentation">
        {enable ? (
          <iframe
            width="720"
            height="405"
            src={`https://www.youtube.com/embed/${codigo}?autoplay=1&rel=0&color=white`}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <div
            role="presentation"
            className={styles.thumbnail_youtube}
            onClick={() => setEnabled(true)}
            onKeyDown={() => setEnabled(true)}
          >
            <picture>
              <source
                media="(max-width: 540px)"
                srcSet={imagemMobile}
                width={540}
                height={406}
              />
              <source
                media="(min-width: 540px)"
                srcSet={imagemDesk}
                width={720}
                height={406}
              />
              <img
                src={imagemMobile}
                alt=""
                width={540}
                height={406}
                loading="lazy"
              />
            </picture>
            <div className={styles.thumb_icon} role="presentation">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
              </svg>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
