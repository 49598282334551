import { useApplySearchState } from 'src/sdk/search/state'
import { ITEMS_PER_PAGE } from 'src/constants'
import ProductGallery from 'src/components/sections/ProductGallery'
import type { SearchState } from '@faststore/sdk'
import {
  formatSearchState,
  parseSearchState,
  SearchProvider,
} from '@faststore/sdk'
import slugify from 'slugify'
import type { SearchSort } from '@faststore/sdk/dist/types'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

export interface ListagemDeProdutosProps {
  sort?: SearchSort
  tipo?: string
  valor?: string | any
  hideFilters: boolean
}

interface selectedFacetsProps {
  selectedFacets: Array<{
    key: string
    value: string
  }>
}

const useSearchParamsCustom = ({
  selectedFacets,
}: selectedFacetsProps): SearchState => {
  const { asPath } = useRouter()

  const hrefState = useMemo(() => {
    const newState = parseSearchState(new URL(asPath, 'http://localhost'))

    // In case we are in an incomplete url
    if (newState.selectedFacets.length === 0) {
      newState.selectedFacets = selectedFacets
    }

    return formatSearchState(newState).href
  }, [asPath, selectedFacets])

  return useMemo(() => parseSearchState(new URL(hrefState)), [hrefState])
}

export const ListagemDeProdutos = ({
  tipo,
  valor,
  hideFilters,
}: ListagemDeProdutosProps) => {
  let selectedFacets: Array<{ key: string; value: string }> = []

  switch (tipo) {
    case 'Coleção':
      selectedFacets = [{ key: 'productClusterIds', value: valor }]
      break

    case 'Marca':
      selectedFacets = [
        { key: 'brand', value: slugify(valor.toLowerCase().trim()) },
      ]
      break

    case 'Departamento':
      selectedFacets = [
        { key: 'category-1', value: slugify(valor.toLowerCase().trim()) },
      ]
      break

    case 'Categoria':
      selectedFacets = [
        { key: 'category-2', value: slugify(valor.toLowerCase().trim()) },
      ]
      break

    default:
      break
  }

  const applySearchState = useApplySearchState()

  const searchParams = useSearchParamsCustom({ selectedFacets })

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={hideFilters ? 20 : ITEMS_PER_PAGE}
      {...searchParams}
    >
      <ProductGallery
        title="Listagem de Produtos"
        hideFilters={hideFilters}
        idList={searchParams.selectedFacets[0].value}
      />
    </SearchProvider>
  )
}
