import styles from '../styles/loader.module.scss'

export interface LoaderShelfItemProps {
  items: number
}

export const LoaderShelfItem = ({ items }: LoaderShelfItemProps) => {
  const arrMap = Array(items).fill('')

  return (
    <>
      {arrMap.map((_, i) => {
        return (
          <div
            key={`loader_shelf_pp_${i}`}
            className={`${styles.pp_item_product} ${
              items === 1 ? styles.oneitem : styles.moreitens
            }`}
          >
            <div className={styles.foto}>
              <div className={`${styles.picture} animate-pulse`} />
            </div>

            <div className={styles.texto}>
              <div className={`${styles.titulo} animate-pulse`} />
              <div className={`${styles.subtitulo} animate-pulse`} />
              <div className={`${styles.precode} animate-pulse`} />
              <div className={`${styles.precopor} animate-pulse`} />
              <div className={`${styles.btcomprar} animate-pulse`} />
            </div>
          </div>
        )
      })}
    </>
  )
}
