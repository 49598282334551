import { useState } from 'react'
import { draftjsToHtml } from 'src/utils'

export interface SeoTextProps {
  text: string
  draftjs?: boolean
  title?: string
}

export const SeoText = ({ title, text, draftjs }: SeoTextProps) => {
  const [opened, setOpened] = useState(false)
  let txt = text

  if (draftjs) {
    txt = draftjsToHtml(txt)
  }

  const toggleContent = () => {
    setOpened(!opened)
  }

  return (
    <div className="container relative transition-all duration-300 px-3 pb-4">
      {title && <div className="text-sm text-center mb-[10px]">{title}</div>}
      <div
        dangerouslySetInnerHTML={{ __html: txt }}
        className={`text-[13px] overflow-hidden transition-all text-[#828282] duration-300 ${
          opened ? 'max-h-[1000px]' : 'max-h-[40px]'
        }`}
      />
      <span
        className={`cursor-pointer text-[13px] absolute underline opened p-0.5 bg-white bg-opacity-60 font-semibold ${
          opened ? '-bottom-0' : '-bottom-[5px]'
        } lg:right-3 lg:left-auto left-3`}
        onClick={toggleContent}
        onKeyDown={toggleContent}
        role="none"
      >
        {opened ? 'fechar' : '...continue lendo'}
      </span>
    </div>
  )
}
