import { draftjsToHtml } from 'src/utils'
import { SeoText } from 'src/components/molecule/SeoText'
import styles from './conteudo.module.scss'

interface ConteudoProps {
  titulo?: string
  descricao: string
  tipo: string
  conteudo_extenso?: string
}

export const Conteudo = ({
  titulo,
  descricao,
  tipo,
  conteudo_extenso = 'false',
}: ConteudoProps) => {
  return (
    <>
      {tipo === 'Header' ? (
        <div
          className={`${styles.Conteudo} container`}
          data-type={tipo}
          data-content-extended={conteudo_extenso}
        >
          <h3 data-title-header data-testid="name-page">
            {titulo}
          </h3>
          <div
            data-content-text
            dangerouslySetInnerHTML={{ __html: draftjsToHtml(descricao) }}
            data-testid="description-page"
          />
        </div>
      ) : (
        <SeoText title={titulo} text={descricao} draftjs data-type={tipo} />
      )}
    </>
  )
}
