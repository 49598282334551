import { PDPSectionContent } from 'src/components/molecule/PDPSectionContent'
import { FormDermaclub } from './FormDermaclub'

export interface BoxDermaclubPDPProps {
  index: number
  active: string
  gtin: string
}

export const BoxDermaclubPDP = ({ active, gtin }: BoxDermaclubPDPProps) => {
  if (active === 'false') {
    return <></>
  }

  return (
    <PDPSectionContent title="DermaClub">
      <FormDermaclub gtin={gtin} />
    </PDPSectionContent>
  )
}
