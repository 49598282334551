import { useEffect, useState } from 'react'
import { BannerImage } from 'src/components/ui/BannerImage'
import { formatPrice } from 'src/utils'
import { BuyButton } from 'src/components/ui/Button/BuyButton'
import DiscountBadge from 'src/components/ui/DiscountBadge'

import styles from './styles/ppshelf.module.scss'

import type {
  ShelfPiscouPerdeuProductItens,
  ShelfPiscouPerdeuProduct,
} from './PiscouPerdeuProducts'
import { ProdutoEsgotado } from './esgotado/ProdutoEsgotado'

export interface ShelfPiscouPerdeuItemProps {
  product: ShelfPiscouPerdeuProduct
  skuDia: string | string[] | undefined
  quantidade?: number
}

/**
 * Recebe 1 produto e monta a shelf que será exibida
 */

export const ShelfPiscouPerdeuItem = ({
  product,
  skuDia,
  quantidade,
}: ShelfPiscouPerdeuItemProps) => {
  const [skuEscolhido, setskuEscolhido] =
    useState<ShelfPiscouPerdeuProductItens>()

  // estado caso o produto esteja esgotado
  const [esgotado, setEsgotado] = useState(false)

  const precode = skuEscolhido
    ? skuEscolhido.sellers[0].commertialOffer.ListPrice
    : 99999

  const precopor = skuEscolhido
    ? skuEscolhido.sellers[0].commertialOffer.Price
    : 99999

  useEffect(() => {
    const skuFilter = product.items.filter((item) => {
      return item.itemId === skuDia
    })

    setskuEscolhido(skuFilter.pop())

    // identifica se um produto está esgotado ou não

    if (precode === precopor) {
      setEsgotado(true)
    } else {
      setEsgotado(false)
    }
  }, [skuDia, product.items, precode, precopor])

  const link = `${product.link.replace(
    'epocacosmeticos.vtexcommercestable',
    'www.epocacosmeticos'
  )}?skuId=${skuDia}`

  const relativeLink = `/${product.linkText}/p?skuId=${skuDia}`
  const imageId = skuEscolhido?.images[0].imageId ?? ''
  const imgDesktop =
    skuEscolhido?.images[0].imageUrl.replace(imageId, `${imageId}-220-220`) ??
    ''

  const imgMobile =
    skuEscolhido?.images[0].imageUrl.replace(imageId, `${imageId}-180-180`) ??
    ''

  const image = {
    alt: skuEscolhido?.name ?? '',
    href: link,
    imgDesktop,
    imgMobile,
  }

  const listPrice = formatPrice(precode)
  const priceArr = skuEscolhido
    ? skuEscolhido.sellers[0].commertialOffer.Price.toFixed(2).split('.')
    : ''

  const priceVal = <span className={styles.price_val}>{priceArr[0]},</span>
  const priceCents = <span className={styles.price_cents}>{priceArr[1]}</span>

  const FlagDesconto = () => {
    // se os preços são iguais não mostra a flag, pois não tem desconto
    if (precode === precopor) {
      return <></>
    }

    return (
      <div className={styles.pp_flag_desconto}>
        <div className={styles.desconto}>
          <DiscountBadge listPrice={precode} spotPrice={precopor} />
        </div>
        <span className={styles.offtag}>OFF</span>
      </div>
    )
  }

  if (!skuEscolhido) {
    // se não tiver sku para mostrar, não mostra nada
    return <></>
  }

  const style_pp = () => {
    if (quantidade === 1) {
      return styles.style_pp_1_item
    }

    if (quantidade === 2) {
      return styles.style_pp_2_itens
    }

    return styles.style_pp_4_itens
  }

  return (
    <div
      className={` ${style_pp()} ${styles.pp_item_product}`}
      data-pp-item-product
    >
      <div className={styles.pp_image}>
        {skuEscolhido && <FlagDesconto />}
        {skuEscolhido && <BannerImage type="image" image={image} />}
      </div>

      <div className={styles.pp_content}>
        <h4 className={styles.pp_brand}>{product.brand}</h4>
        <p className={styles.pp_title}>{product.productTitle}</p>
        <div className={styles.wrapper_list_price}>
          de:
          <p className={styles.list_price}>{listPrice}</p>
        </div>
        <div className={styles.price_box}>
          <div className={styles.wrapper_cifrao}>
            <span className={styles.wrapper_cifrao_por}>por</span>
            <span className={styles.wrapper_cifrao_simbol}>R$</span>
          </div>
          <div className={styles.preco_por}>
            {priceVal}
            {priceCents}
          </div>
        </div>

        <BuyButton
          type="pp"
          slug={relativeLink}
          text="Comprar >"
          className={styles.buy_button}
        />
      </div>
      {esgotado && <ProdutoEsgotado />}
    </div>
  )
}
