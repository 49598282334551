import { useEffect, useState } from 'react'
import { BannerImage } from 'src/components/ui/BannerImage'
import type { PiscouPerdeuResponse } from 'src/utils'
import { ModalBox } from 'src/components/molecule/ModalBox'
import { SimpleButton } from 'src/components/ui/Button/SimpleButton'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'

import type { PiscouPerdeuProps } from './index'
import { ShelfPiscouPerdeu } from './ShelfPiscouPerdeu'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
import { LoaderCounter } from './loaders/LoaderCounter'
import { LoaderShelfItem } from './loaders/LoaderShelfItem'

import styles from './styles/piscouperdeu1item.module.scss'

export interface PiscouPerdeu1ItemProps {
  apiContent: PiscouPerdeuResponse | undefined
  cmsContent: PiscouPerdeuProps
}

export const PiscouPerdeu1Item = ({
  apiContent,
  cmsContent,
}: PiscouPerdeu1ItemProps) => {
  // informações que serão passadas para o componente banner image
  const bannerProps = {
    alt: cmsContent.banner.alt,
    href: cmsContent.banner.href,
    imgDesktop: cmsContent.banner.desktop,
    imgMobile: cmsContent.banner.mobile,
  }

  // informação de aparencia usada para customizar o visual de cada piscou perdeu.
  const theme = cmsContent.theme
    ? cmsContent.theme.toLocaleLowerCase()
    : cmsContent.theme === 'Selecione...'
    ? ''
    : cmsContent.theme?.toLocaleLowerCase()

  // data final que será usada para iniciar o contador
  const finish = apiContent?.final_date
  // impede o contador carregar sem a informação do cms chegar, evitando erros de renderização.
  const [load, setLoad] = useState(false)
  // variavel que controla o modalBox
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (apiContent?.final_date) {
      setLoad(true)
    }
  }, [apiContent?.final_date])

  // mini componente que será exibido ao finalizar a contagem
  const Completo = () => {
    return (
      <>
        <p style={{ textAlign: 'center' }}>Promoção finalizada</p>
      </>
    )
  }

  const bgImage = cmsContent.background_image
  let css = ''

  const hasImage = bgImage.mobile && bgImage.desktop

  if (hasImage) {
    css = `@media (max-width: 1279px) {
            .piscou-perdeu-1-item.${theme} .content_counter {
              background-image: url(${bgImage.mobile});
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          @media (min-width: 1280px) {
            .piscou-perdeu-1-item.${theme} .content_counter {
              background-image: url(${bgImage.desktop});
              background-repeat: no-repeat;
              background-size: cover;
            }
          }`
  }

  return (
    <>
      {/* Estilo de background do content_counter */}
      {hasImage && <style scoped>{css}</style>}

      <section
        className={`piscou-perdeu-1-item ${styles.pp1Item} ${
          theme === 'selecione...' ? 'default' : theme
        }`}
      >
        <div className={`container ${styles.ppcontainer}`}>
          <div className={`content_counter ${styles.contentCounter}`}>
            <div className={styles.contentText}>
              <div className={styles.title} data-title>
                <p data-content-title>
                  <span>{cmsContent.titulo}</span>
                </p>
              </div>

              <div className={styles.logo}>
                <picture>
                  <img
                    src={cmsContent.logo}
                    alt="Piscou Perdeu"
                    width={200}
                    height={100}
                    loading="lazy"
                  />
                </picture>
              </div>

              <div className={styles.counter} data-testid="counterFlip">
                {!load ? (
                  <LoaderCounter />
                ) : (
                  <FlipClockCountdown
                    to={finish ?? '2022-01-01 00:00:00'}
                    onComplete={() => null}
                    className="counter_flip"
                    data-counter
                  >
                    <Completo />
                  </FlipClockCountdown>
                )}
              </div>
            </div>

            <div className={styles.shelf_pp}>
              {!load ? (
                <LoaderShelfItem items={1} />
              ) : (
                <ShelfPiscouPerdeu
                  items={[apiContent?.produto]}
                  skuDia={apiContent?.skuDia}
                  quantidade={1}
                />
              )}
              <div className={styles.content_button_regras}>
                <SimpleButton onClick={() => setModalOpen(true)}>
                  Confira as regras
                </SimpleButton>
              </div>
            </div>
          </div>
          <div className={styles.banner}>
            <BannerImage type="banner" image={bannerProps} />
          </div>
        </div>
      </section>

      <ModalBox
        opened={modalOpen}
        toggle={setModalOpen}
        content={cmsContent.regras.content}
      />
    </>
  )
}
