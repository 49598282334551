import { useSearch } from '@faststore/sdk'
import type { StylesConfig } from 'react-select'
import Select from 'react-select'

const options = [
  { value: 'score_desc', label: 'Relevância' },
  { value: 'price_desc', label: 'Maior preço' },
  { value: 'price_asc', label: 'Menor preço' },
  { value: 'orders_desc', label: 'Mais vendidos' },
  { value: 'name_asc', label: 'Nome, A-Z' },
  { value: 'name_desc', label: 'Nome, Z-A' },
  { value: 'discount_desc', label: 'Desconto' },
  { value: 'release_desc', label: 'Data de lançamento' },
]

function Sort() {
  const { state, setState } = useSearch()

  const customStyles: StylesConfig = {
    option: (provided, styleState) => ({
      ...provided,
      backgroundColor: styleState.isSelected ? '#E70D91' : '#fff',
      cursor: 'pointer',
      transition: 'all .3s',
      '&:hover': {
        backgroundColor: '#ed97a2',
        color: '#fff',
      },
    }),
    control: (provided) => ({
      ...provided,
      width: '200px',
      boxShadow: '0 0 #E70D91',
      borderColor: '#E70D91',
      '&:focus-visible': {
        outlineColor: '#E70D91',
      },
      '&:focus': {
        outlineColor: '#E70D91',
      },
      '&:hover': {
        borderColor: '#E70D91',
      },
      '&:active': {
        outlineColor: '#E70D91',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
  }

  return (
    <Select
      defaultValue={options[0]}
      options={options}
      styles={customStyles}
      onChange={(option: any) => {
        setState({ ...state, sort: option.value })
      }}
    />
  )
}

export default Sort
