import styles from './bannerimage.module.scss'

export interface BannerImageProps {
  image: {
    alt: string
    href?: string
    imgDesktop: string
    imgMobile: string
  }
  type?: string
}

export const BannerImage = ({ image, type }: BannerImageProps) => {
  const hasLink = !!image.href
  const picture = (
    <picture>
      <source media="(max-width: 1279px)" srcSet={image.imgMobile} />
      <source media="(min-width: 1280px)" srcSet={image.imgDesktop} />
      <img
        src={image.imgMobile}
        alt={image.alt}
        width="100%"
        height="100%"
        loading="lazy"
        className={styles.image}
      />
    </picture>
  )

  return (
    <div
      className={
        type === 'banner' ? styles.style_banner : styles.style_card_image
      }
    >
      {hasLink ? (
        <a href={image.href} className={styles.link}>
          {picture}
        </a>
      ) : (
        picture
      )}
    </div>
  )
}
