import Image from 'next/image'
import Link from 'next/link'
import { Carrossel } from 'src/components/common/Carrossel'
import type { BannerCmsProps } from 'src/components/molecule/CmsBanner'
import { CmsBanner } from 'src/components/molecule/CmsBanner'
import type { Sections } from 'src/components/sections/HeaderComBanner/types'
import { draftjsToHtml } from 'src/utils'
import styles from './marcas.module.scss'

function MarcasHeader({ section }: Sections) {
  const description = draftjsToHtml(section.descricao) ?? ''

  return (
    <section className={`section ${styles.dataFsBrands} shadow-xl`}>
      <div className="container">
        <div className="wrapper min-h-[290px] flex flex-col xl:flex-row">
          <div className="contentText max-w-full xl:w-[325px] flex-shrink-0 p-3 rounded-tl-sm rounded-bl-sm">
            <h1 className="text-3xl font-semibold mb-2">
              {section.titulo ?? ''}
            </h1>
            <div
              className={`${styles.descricao} text-sm`}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <figure className="xl:w-[180px]">
              <Link href="#" className="cursor-pointer">
                <a>
                  <Image
                    src={section.logo}
                    alt=""
                    width={180}
                    height={90}
                    loading="lazy"
                  />
                </a>
              </Link>
            </figure>
          </div>

          <div className="banners flex items-center flex-grow overflow-hidden">
            {section.banner?.length > 1 ? (
              <Carrossel
                classname="w-full relative"
                slidesToShow={1}
                overRideClasses
              >
                {section.banner?.map((banner: BannerCmsProps, i: any) => (
                  <CmsBanner key={`bannerDep_${i}`} banner={banner} />
                ))}
              </Carrossel>
            ) : (
              <CmsBanner banner={section.banner[0]} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MarcasHeader
