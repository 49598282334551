import { ProductItem } from 'src/components/molecule/ProductItem'
import { useGetReviews } from 'src/hooks/useGetReviews'
import { useRichPlacemments } from 'src/hooks/useRichPlacemments'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import { draftjsToHtml, getStorageRcs } from 'src/utils'
import styles from './lancamento.module.scss'
import { PopularShelf } from './PopularShelf'

interface LancamentoEDestaqueProps {
  titleLancamento: string
  skuId: string
  descricao: string
}

export const LancamentoEDestaque = (props: LancamentoEDestaqueProps) => {
  const { descricao, skuId, titleLancamento } = props
  const descricaoAjustado = draftjsToHtml(descricao)
  const prodsIds: any[] = []
  let item: any = {}

  const _rcs = getStorageRcs('brand_page.marca_especial_mod2_carousel1')

  const products = useProductsQuery({
    first: 10,
    after: '',
    sort: 'score_desc',
    selectedFacets: [],
    term: `sku:${skuId}`,
  })

  const richPlacements: any = useRichPlacemments({
    fpb: String(products?.edges[0].node.brand.brandName),
    placement: 'brand_page.marca_especial_mod2_carousel1',
    rcs: _rcs,
  })

  products?.edges.map((productItem) => {
    const prodId: any = productItem.node.isVariantOf.productGroupID

    prodsIds.push(prodId)

    return null
  })
  const reviews = useGetReviews(prodsIds)

  if (products) {
    item = {
      slug: products?.edges[0].node.slug,
      name: products?.edges[0].node.name,
      gtin: '',
      id: products?.edges[0].node.id,
      brand: {
        brandName: products?.edges[0].node.brand.brandName,
        name: products?.edges[0].node.brand.name,
      },
      isVariantOf: {
        productGroupID: products?.edges[0].node.isVariantOf.productGroupID,
        name: products?.edges[0].node.isVariantOf.name,
      },
      image: [
        {
          url: products?.edges[0].node.image[0].url,
          alternateName: products?.edges[0].node.image[0].alternateName,
        },
      ],
      offers: {
        lowPrice: products?.edges[0].node.offers.lowPrice,
        offers: [
          {
            price: products?.edges[0].node.offers.offers[0].price,
            listPrice: products?.edges[0].node.offers.offers[0].listPrice,
            quantity: products?.edges[0].node.offers.offers[0].quantity,
          },
        ],
      },
    }
  }

  return (
    <section className={`container ${styles.lancamentoDestaque}`}>
      <div className={styles.lancamento}>
        <div className={styles.titulo}>
          <h2>{titleLancamento}</h2>
        </div>
        <div className={styles.lancamentoContent}>
          <div className={styles.lancamentoProduct}>
            {products && (
              <ProductItem
                key={item?.id}
                item={item}
                skus={products?.edges[0].node.sku}
                type="vtex"
                review={reviews[prodsIds[0]]}
              />
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: descricaoAjustado }}
            className={styles.lancamentoDescricao}
          />
        </div>
      </div>
      <div className={styles.destaque}>
        <div className={styles.titulo}>
          <h2>
            {richPlacements?.RichPlacements?.placements[0]
              ? richPlacements?.RichPlacements?.placements[0].strategyMessage
              : 'Os mais populares'}
          </h2>
          {richPlacements?.RichPlacements?.placements[0]
            ?.recommendedProducts && (
            <PopularShelf
              products={
                richPlacements?.RichPlacements?.placements[0]
                  ?.recommendedProducts
              }
            />
          )}
        </div>
      </div>
    </section>
  )
}
