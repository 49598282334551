import { Carrossel } from 'src/components/common/Carrossel'
import type { BannerCmsProps } from 'src/components/molecule/CmsBanner'
import { CmsBanner } from 'src/components/molecule/CmsBanner'
import { draftjsToHtml } from 'src/utils'
import MarcasHeader from '../../molecule/MarcaHeader'

export interface HeaderComBannerProps {
  active: boolean
  categoria: string
  titulo: string
  descricao: string
  banner: BannerCmsProps[]
  textoSeo: string
  vitrinePatrocinada: boolean
  logo: string
}

export const HeaderComBanner = (props: HeaderComBannerProps) => {
  const descricao = props.descricao ? draftjsToHtml(props.descricao) : ''

  const sections = {
    active: props.active,
    categoria: props.categoria,
    titulo: props.titulo,
    descricao: props.descricao,
    banner: props.banner,
    textoSeo: props.textoSeo,
    vitrinePatrocinada: props.vitrinePatrocinada,
    logo: props.logo,
  }

  return (
    <>
      {props.logo ? (
        <MarcasHeader section={sections} />
      ) : (
        <section className="HeaderComBanner w-full shadow-md bg-red-50">
          <div>
            <div className="wrapper container min-h-[290px] flex flex-col lg:flex-row">
              <div className="contentText max-w-full lg:w-[325px] flex-shrink-0 p-3 rounded-tl-sm rounded-bl-sm">
                <h1 className="mt-[5px] text-3xl font-semibold mb-2">
                  {props.titulo}
                </h1>
                <div
                  className="descricao text-sm"
                  dangerouslySetInnerHTML={{ __html: descricao }}
                />
              </div>

              <div className="banners flex items-center flex-grow overflow-hidden">
                {props.banner.length > 1 ? (
                  <Carrossel
                    classname="w-full relative"
                    slidesToShow={1}
                    overRideClasses
                  >
                    {props.banner.map((banner, i) => (
                      <CmsBanner key={`bannerCat_${i}`} banner={banner} />
                    ))}
                  </Carrossel>
                ) : (
                  <CmsBanner banner={props.banner[0]} />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
