import { useEffect } from 'react'

interface CupomTooltipProps {
  setCopied: (cupom: string) => void
  copied: string
  nameItem: string
}

export const CupomTooltip = (props: CupomTooltipProps) => {
  const { copied, nameItem, setCopied } = props

  useEffect(() => {
    setTimeout(() => {
      setCopied('')
    }, 2000)
  })

  return (
    <>
      {copied === nameItem && (
        <div className="bg-white absolute ml-2 mt-1">
          <span className="text-[8px] md:text-xxs font-bold">
            Copiado com sucesso!
          </span>
        </div>
      )}
    </>
  )
}
