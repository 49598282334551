import { Image } from 'src/components/ui/Image'
import styles from './colecoes-marcas.module.scss'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

interface ColecoesMarcasProps {
  name: string
  allItems: Array<{
    nomeLink: string
    imagem: string
    nome: string
  }>
}

export const ColecoesMarcas = ({ name, allItems }: ColecoesMarcasProps) => {
  const screen = window ? window.screen.width : ''

  return (
    <div className={`container ${styles.colecoesMarcas}`}>
      <h2>{name}</h2>
      <div className={styles.colectionList}>
        <div className={`carrossel ${styles.divCarrossel} `}>
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={180}
            slidesPerView={4}
            slidesPerGroup={4}
            loop={!(screen >= 1280 && allItems.length <= 4)}
            autoplay={false}
            breakpoints={{
              375: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: -20,
              },
              425: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: -50,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: -100,
              },
              912: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: -100,
              },
              1024: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: -50,
              },
              1280: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 0,
              },
            }}
          >
            {allItems.map((item, key) => {
              const colectionId = item.nome

              return (
                <SwiperSlide key={`slide_${colectionId}_${key}`}>
                  <div key={key} className={styles.colectionItem}>
                    <a href={item.nomeLink}>
                      <Image
                        src={item.imagem}
                        alt={item.nome}
                        width={280}
                        height={280}
                        className={styles.colectionImage}
                      />
                      <div className={styles.divText}>
                        <span>{item.nome}</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
