import { useEffect, useState } from 'react'
import type { PiscouPerdeuResponse } from 'src/utils'
import { getItemsPiscouPerdeu, draftjsToHtml } from 'src/utils'

import { PiscouPerdeu1Item } from './PiscouPerdeu1Item'
import { PiscouPerdeu2Itens } from './PiscouPerdeu2Itens'
import { PiscouPerdeu4Itens } from './PiscouPerdeu4Itens'

export interface PiscouPerdeuProps {
  active: boolean
  background_image: {
    desktop: string
    mobile: string
  }
  banner: {
    alt: string
    desktop: string
    href: string
    mobile: string
  }
  logo: string
  qtd_skus: string
  theme?: string
  titulo: string
  regras: {
    content: string
  }
}

export const PiscouPerdeu = (props: PiscouPerdeuProps) => {
  const [content, setContent] = useState<PiscouPerdeuResponse>()
  // draftjsToHtml transforma de draftjs (que é um json) que vem do cms para um formato em html
  const regras = draftjsToHtml(props.regras.content)
  const cmsContent = { ...props, regras: { content: regras } }

  useEffect(() => {
    if (props.active) {
      const items = getItemsPiscouPerdeu(props.qtd_skus)

      items.then((data) => {
        setContent(data)
      })
    }
  }, [props.active, props.qtd_skus])

  if (props.active) {
    if (props.qtd_skus === '1 item') {
      return <PiscouPerdeu1Item apiContent={content} cmsContent={cmsContent} />
    }

    if (props.qtd_skus === '2 itens') {
      return <PiscouPerdeu2Itens apiContent={content} cmsContent={cmsContent} />
    }

    if (props.qtd_skus === '4 itens') {
      return <PiscouPerdeu4Itens apiContent={content} cmsContent={cmsContent} />
    }
  }

  return <></>
}
