import type { DadosMarcaProps } from './SacMarcas'
import { XIcon } from '../../ui/ImageGallery/Icons'

interface DadosMarcaModalProps {
  dadosMarca: DadosMarcaProps
  visible: boolean
  close: () => void
}

export const DadosMarcaModal = ({
  dadosMarca,
  visible,
  close,
}: DadosMarcaModalProps) => {
  // modal por marca
  return (
    <>
      {visible && (
        <div
          className="bg-[rgb(0,0,0,0.7)] w-full h-full z-[100] left-0 right-0 top-0 bottom-0 fixed"
          data-testid="modal-sac"
        >
          <div className="flex fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[260px] md:min-w-[400px] min-h-[180px] md:min-h-[280px]">
            <div className="bg-white flex-1 h-auto pt-[15px] pb-[15px] pl-[15px] pr-[15px]">
              {dadosMarca.logo && (
                <img src={dadosMarca.logo} alt="Logo Marca" className="mb-1" />
              )}
              <div className="flex mb-1">
                <p className="text-[#666666] mr-1">Marca: </p>
                <p>{dadosMarca.nome}</p>
              </div>
              {dadosMarca.fornecedor && (
                <div className="flex mb-1">
                  <p className="text-[#666666] mr-1">Fornecedor: </p>
                  <p>{dadosMarca.fornecedor}</p>
                </div>
              )}
              {dadosMarca.contato?.telefone && (
                <div className="flex mb-1">
                  <p className="text-[#666666] mr-1">Telefone: </p>
                  <p>{dadosMarca.contato.telefone}</p>
                </div>
              )}
              {dadosMarca.contato?.email && (
                <div className="sm:flex sm:mb-1">
                  <p className="text-[#666666] mr-1">E-mail:</p>
                  <p>{dadosMarca.contato.email}</p>
                </div>
              )}
              {dadosMarca.contato?.link && dadosMarca.contato.textoLink && (
                <div className="flex mb-1">
                  <p className="text-[#666666] mr-1">Contato: </p>
                  <a href={dadosMarca.contato.link}>
                    {dadosMarca.contato.textoLink}
                  </a>
                </div>
              )}
            </div>
            <div className="mt-[-22px] ml-[3px]">
              <button onClick={close} data-testid="button-close-modal">
                <XIcon color="white" tamanho="w-4 h-4" />
              </button>
            </div>
          </div>
          <div
            className="w-full h-full"
            onClick={() => close()}
            onKeyDown={() => close()}
            role="none"
          />
        </div>
      )}
    </>
  )
}
