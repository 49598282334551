import styles from './mosaico.module.scss'

interface PosicaoProps {
  desktop: string
  mobile: string
  alt: string
  href: string
}

interface MosaicoProps {
  posicao1: PosicaoProps
  posicao2: PosicaoProps
  posicao3: PosicaoProps
  posicao4: PosicaoProps
  posicao5: PosicaoProps
}

export const Mosaico = (props: MosaicoProps) => {
  const { posicao1, posicao2, posicao3, posicao4, posicao5 } = props

  return (
    <>
      <section
        className={`${styles.mosaico} ${styles.gridTemplate} container grid grid-template mt-1 mb-3`}
        data-testid="sectionMosaico"
      >
        <div className={styles.posicao1} data-testid="posicao1">
          <a
            href={posicao1.href ?? '#'}
            data-testid="linkMosaico1"
            aria-label={posicao1.alt ?? 'link 1 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao1.mobile} />
              <source media="(min-width: 769px)" srcSet={posicao1.desktop} />
              <img
                src={posicao1.mobile}
                alt={posicao1.alt ?? 'imagemMosaico1'}
                data-testid="imagemMosaico1"
                className="w-full h-auto"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </picture>
          </a>
        </div>
        <div className={styles.posicao2} data-testid="posicao2">
          <a
            href={posicao2.href ?? '#'}
            data-testid="linkMosaico2"
            aria-label={posicao2.alt ?? 'link 2 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao2.mobile} />
              <source media="(min-width: 769px)" srcSet={posicao2.desktop} />
              <img
                src={posicao2.mobile}
                alt={posicao2.alt ?? 'imagemMosaico2'}
                data-testid="imagemMosaico2"
                className="w-full h-auto"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </picture>
          </a>
        </div>
        <div className={styles.posicao3} data-testid="posicao3">
          <a
            href={posicao3.href ?? '#'}
            data-testid="linkMosaico3"
            aria-label={posicao3.alt ?? 'link 3 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao3.mobile} />
              <source media="(min-width: 769px)" srcSet={posicao3.desktop} />
              <img
                src={posicao3.mobile}
                alt={posicao3.alt ?? 'imagemMosaico3'}
                className="h-full w-auto"
                data-testid="imagemMosaico3"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </picture>
          </a>
        </div>
        <div className={styles.posicao4} data-testid="posicao4">
          <a
            href={posicao4.href ?? '#'}
            data-testid="linkMosaico4"
            aria-label={posicao1.alt ?? 'link 4 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao4.mobile} />
              <source media="(min-width: 769px)" srcSet={posicao4.desktop} />
              <img
                src={posicao4.mobile}
                alt={posicao4.alt ?? 'imagemMosaico4'}
                data-testid="imagemMosaico4"
                className="w-full h-auto"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </picture>
          </a>
        </div>
        <div className={styles.posicao5} data-testid="posicao5">
          <a
            href={posicao5.href ?? '#'}
            data-testid="linkMosaico5"
            aria-label={posicao1.alt ?? 'link 5 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao5.mobile} />
              <source media="(min-width: 769px)" srcSet={posicao5.desktop} />
              <img
                src={posicao5.mobile}
                alt={posicao5.alt ?? 'imagemMosaico5'}
                data-testid="imagemMosaico5"
                className="w-full h-auto"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </picture>
          </a>
        </div>
      </section>
    </>
  )
}
