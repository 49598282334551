import { CategoriaItem } from './CategoriaItem'
import styles from './categoria-carrossel.module.scss'
import { Carrossel } from './Carrossel'

interface CategoriaCarrosselProps {
  nome: string
  allItems: AllItemsProps[]
}

export interface AllItemsProps {
  nomeColuna: string
  descricao: string
  imagem: string
  href: string
}

export const CategoriaCarrossel = (props: CategoriaCarrosselProps) => {
  const { allItems, nome } = props

  return (
    <section className={styles.categoriaCarrossel}>
      <h2 className={styles.title}>{nome}</h2>
      <Carrossel>
        {allItems.map((item, key) => {
          return <CategoriaItem item={item} key={key} />
        })}
      </Carrossel>
    </section>
  )
}
