import { draftjsToHtml } from 'src/utils'
import { Image } from 'src/components/ui/Image'

import styles from './box-content.module.scss'
import { useState } from 'react'

export interface BoxContentProps {
  /**
   * Posição/Ordem do componente na página, é o index que é passado no loop do `RenderPageSections`
   */
  index: number
  /**
   * Diz se é para exibir ou não o componente
   */
  active: string
  /**
   * Descrição do componente
   */
  descricao: {
    content: string
  }
  /**
   * Ativa ou desativa o botão call to action
   */
  cta: string
  /**
   * Define se a seção vai ser full page ou vai se manter apenas no container
   */
  full_page: boolean
  /**
   * Define o alinamento da descrição (left, right, center)
   */
  alinhamento_descricao: string
  /**
   * Titulo da seção
   */
  titulo: string
  /**
   * Define se o titulo ficará dentro ou fora(acima) da section
   */
  localizacao_title: string
  /**
   * Define o alinamento do titulo (left, right, center)
   */
  alinhamento_title: string
  /**
   * Imagem da seção
   */
  imagem: string
  /**
   * Define a posição da imagem em relação à descrição (left, right)
   */
  posicao_imagem: string
  altura_imagem: string
  largura_imagem: string
  /**
   * **Cor** de fundo da seção
   */
  background: string
  /**
   * Texto do botão call to action
   */
  cta_text: string
  /**
   * Link do botão call to action
   */
  cta_link: string
  /**
   * Ícone do botão call to action
   */
  cta_icone: string
  /**
   * Define a posição do ícone em relação ao texto do CTA (left, right)
   */
  cta_icone_posicao: string
  /**
   * Cor do texto do botão call to action
   */
  cta_color: string
  /**
   * Background do botão call to action quando passar o mouse em cima
   */
  cta_bg_hover: string
  /**
   * Background do botão call to action
   */
  cta_bg: string
  /**
   * Cor do texto Título
   */
  cor_do_titulo: string
  /**
   * Cor do texto da descrição
   */
  cor_da_descricao: string
}

export const BoxContent = ({
  index,
  active,
  descricao,
  cta,
  full_page,
  alinhamento_descricao,
  titulo,
  localizacao_title,
  alinhamento_title,
  imagem,
  posicao_imagem,
  background,
  cta_text,
  cta_link,
  cta_icone,
  cta_icone_posicao,
  cta_color,
  cta_bg_hover,
  cta_bg,
  cor_do_titulo,
  cor_da_descricao,
  altura_imagem,
  largura_imagem,
}: BoxContentProps) => {
  if (!active) {
    return <></>
  }

  const [isHover, setIsHover] = useState(false)
  const textoDescricao = draftjsToHtml(descricao.content)
  const stylesSection: any = {}

  if (background) {
    stylesSection.backgroundColor = background
  }

  const imgWidth = largura_imagem ? parseInt(largura_imagem, 10) : 512
  const imgHeight = largura_imagem ? parseInt(altura_imagem, 10) : 512

  const isInternalLink = cta_link
    ? cta_link.indexOf('/') === 0 ||
      (cta_link && cta_link.indexOf('epocacosmeticos.com.br') > 0)
    : false

  const _target = isInternalLink ? '_self' : '_blank'

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <section className={styles.BoxContent} data-position={index}>
      {localizacao_title === 'acima' && (
        <div className="container">
          <h3
            style={{ color: cor_do_titulo }}
            data-align-title={alinhamento_title}
          >
            {titulo}
          </h3>
        </div>
      )}

      <div className={`${full_page ? '' : 'container'}`} style={stylesSection}>
        <div
          className={`${styles.wrapperContent} ${full_page ? 'container' : ''}`}
        >
          {imagem && (
            <div data-content-img data-position={posicao_imagem}>
              <div style={{ width: imgWidth }}>
                <Image
                  preload
                  alt={titulo}
                  src={imagem}
                  width={imgWidth}
                  height={imgHeight}
                  fetchpriority="high"
                  loading="lazy"
                  data-box-content-img
                />
              </div>
            </div>
          )}

          <div data-content-description>
            {localizacao_title === 'dentro' && (
              <h3
                style={{ color: cor_do_titulo }}
                data-align-title={alinhamento_title}
              >
                {titulo}
              </h3>
            )}
            <div
              style={{ color: cor_da_descricao }}
              data-align-description={alinhamento_descricao}
              dangerouslySetInnerHTML={{ __html: textoDescricao }}
            />

            {cta && (
              <div className={styles.contentCta}>
                <a
                  href={cta_link}
                  target={_target}
                  style={{
                    color: cta_color,
                    backgroundColor: isHover ? cta_bg_hover : cta_bg,
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {cta_icone && (
                    <div data-content-icon>
                      <Image
                        preload
                        alt="icone_cta"
                        src={cta_icone}
                        width={32}
                        height={32}
                        fetchpriority="high"
                        loading="lazy"
                        data-icon-position={cta_icone_posicao}
                      />
                    </div>
                  )}
                  <span>{cta_text}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
