import { useEffect, useState } from 'react'
import { v4 as uuidkey } from 'uuid'

import { ShelfPiscouPerdeuItem } from './ShelfPiscouPerdeuItem'
import type { ShelfPiscouPerdeuProduct } from './PiscouPerdeuProducts'

export interface ShelfPiscouPerdeuProps {
  items: Array<string | undefined>
  skuDia: string | string[] | undefined
  quantidade: number
}

/**
 * Este componente pode ser reaproveitado para todos os piscou perdeu.
 * Ele recebe os produtos e monta quantas forem as colunas, de acordo
 * com a quantidade de produtos (props.items) que é passado.
 */
export const ShelfPiscouPerdeu = ({
  items,
  skuDia,
  quantidade,
}: ShelfPiscouPerdeuProps) => {
  const [products, setProducts] = useState<ShelfPiscouPerdeuProduct[]>([])

  useEffect(() => {
    items?.forEach((item) => {
      if (Array.isArray(item)) {
        const tempProduct: any[] = []

        item.map((productJson) => {
          // transforma cada item em objeto, pois vem como json/string
          tempProduct.push(JSON.parse(productJson)[0])

          return true
        })

        // limita os produtos pegando apenas a quantidade que for passada.
        const limiteProducts = tempProduct.slice(0, quantidade)

        setProducts(limiteProducts)
      } else {
        // se não for um array, quer dizer que é o apenas 1 item,
        // então salva o conteúdo direto no 'products'
        const product: ShelfPiscouPerdeuProduct[] = JSON.parse(item ?? '')

        setProducts(product)
      }

      // setEsgotado(false) // arrumar

      return true
    })
  }, [items, quantidade])

  if (!items) {
    return <></>
  }

  return (
    <>
      {products.map((product, index) => {
        const skuDay = Array.isArray(skuDia) ? skuDia[index] : skuDia

        if (product) {
          if (quantidade === 4) {
            return (
              <ShelfPiscouPerdeuItem
                key={`pp_shelf_${uuidkey()}`}
                product={product}
                skuDia={skuDay}
              />
            )
          }

          return (
            <ShelfPiscouPerdeuItem
              key={`pp_shelf_${uuidkey()}`}
              product={product}
              skuDia={skuDay}
              quantidade={quantidade}
            />
          )
        }

        return <></>
      })}
    </>
  )
}
