import { useState } from 'react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from 'src/components/ui/ImageGallery/Icons'
import styles from './menu-sidebar.module.scss'

interface MenuSidebarProps {
  nomeMarca: string
  linkNomeMarca: string
  allItems: MenuColumns[]
  styleModelo03?: boolean
  styleModelo02?: boolean
}

interface MenuColumns {
  nomeColuna: string
  linkMarca: string
  submenuColuna?: Array<{
    nomeSubmenu: string
    link: string
  }>
}

interface MenuItemProps {
  coluna: MenuColumns
}

const MenuItem = ({ coluna }: MenuItemProps) => {
  let isOpen = false
  const width = window.screen.width ? window.screen.width : ''

  if (width && width < 1280) {
    isOpen = false
  } else {
    isOpen = true
  }

  const [open, setOpen] = useState(isOpen)

  return (
    <>
      <div
        className={styles.menuColumns}
        onClick={() => setOpen((s) => !s)}
        aria-hidden="true"
      >
        <span className={styles.column}>{coluna.nomeColuna}</span>
        {coluna.submenuColuna && coluna.submenuColuna.length !== 0 && (
          <span>
            {open ? (
              <ChevronUpIcon color="black" tamanho="h-4 w-4" />
            ) : (
              <ChevronDownIcon color="black" tamanho="h-4 w-4" />
            )}
          </span>
        )}
      </div>
      <ul className={styles.submenuColumns}>
        {coluna.submenuColuna &&
          coluna?.submenuColuna?.map((subtitulo, key) => {
            return (
              <li
                className={
                  open
                    ? `${styles.conteudo} ${styles.visivel}`
                    : styles.conteudo
                }
                key={key}
              >
                <a href={subtitulo.link ?? '#'} key={key}>
                  <span className={styles.subColumns}>
                    {subtitulo.nomeSubmenu}
                  </span>
                </a>
              </li>
            )
          })}
      </ul>
    </>
  )
}

export const MenuSidebar = (props: MenuSidebarProps) => {
  const { allItems, nomeMarca, linkNomeMarca, styleModelo03, styleModelo02 } =
    props

  return (
    <section
      className={`container ${styles.menuSidebar}`}
      data-fs-menu-marca-modelo03={styleModelo03}
      data-fs-menu-marca-modelo02={styleModelo02}
    >
      {nomeMarca && (
        <h2>
          <a href={linkNomeMarca}>{nomeMarca}</a>
        </h2>
      )}
      <div className={styles.divMenuColumns}>
        {allItems?.map((coluna, index) => {
          return <MenuItem key={index} coluna={coluna} />
        })}
      </div>
    </section>
  )
}
