import type { AllItemsProps } from '.'
import { Image } from 'src/components/ui/Image'
import { draftjsToHtml } from 'src/utils'
import styles from './categoria-carrossel.module.scss'

interface CategoriaItemProps {
  item: AllItemsProps
}

export const CategoriaItem = ({ item }: CategoriaItemProps) => {
  const descricao = draftjsToHtml(item.descricao)
  const descrString = descricao.replace(/(<([^>]+)>)/gi, '')

  return (
    <div className={styles.categoriaItem}>
      <a href={item.href}>
        <div>
          <Image
            src={item.imagem}
            alt={item.nomeColuna}
            width={198}
            height={198}
            className={styles.image}
          />
          <h2>{item.nomeColuna}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: descrString }}
            className={styles.description}
          />
        </div>
      </a>
    </div>
  )
}
