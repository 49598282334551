import { draftjsToHtml } from 'src/utils'
import styles from './imagem-e-descricao.module.scss'

interface ImagemEDescricaoProps {
  titulo: string
  imagemDesk: string
  imagemMobile: string
  widthDesktop: string
  widthMobile: string
  heightDesktop: string
  heightMobile: string
  descricao: string
  link: string
  styleModelo2?: boolean
  styleModelo3?: boolean
  styleModelo4?: boolean
}

export const ImagemEDescricao = (props: ImagemEDescricaoProps) => {
  const {
    descricao,
    heightDesktop,
    heightMobile,
    imagemDesk,
    imagemMobile,
    titulo,
    widthDesktop,
    widthMobile,
    link,
    styleModelo2,
    styleModelo3,
    styleModelo4,
  } = props

  const descricaoAjustada = descricao ? draftjsToHtml(descricao) : ''

  const descrString = descricaoAjustada.replace(/(<([^>]+)>)/gi, '')

  return (
    <div
      className={`container ${styles.imagemEDescricao}`}
      data-fs-historia-marca-modelo02={styleModelo2}
      data-fs-historia-marca-modelo03={styleModelo3}
      data-fs-historia-marca-modelo04={styleModelo4}
    >
      {titulo && (
        <div className={styles.title}>
          <h2>{titulo}</h2>
        </div>
      )}
      <div className={descrString ? styles.descriptionBody : styles.imageBody}>
        <a href={link || '#'}>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={imagemMobile}
              width={widthMobile}
              height={heightMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={imagemDesk}
              width={widthDesktop}
              height={heightDesktop}
            />
            <img
              src={imagemMobile}
              alt=""
              width={widthMobile}
              height={heightMobile}
              loading="lazy"
              className={styles.renderImage}
            />
          </picture>
        </a>
        {descrString && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: descrString }}
          />
        )}
      </div>
    </div>
  )
}
