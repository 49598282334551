import { gql } from '@faststore/graphql-utils'
import { useCookies } from 'react-cookie'
import type { RichBannersQueryQuery } from '@generated/graphql'

import { useQuery } from '../sdk/graphql/useQuery'
import { useSession } from 'src/sdk/session'
import { HashRich } from 'src/utils/RichRelevance'

export const query = gql`
  query RichBannersQuery(
    $placements: String
    $userId: String
    $vtexCookie: String
  ) {
    RichBanners(
      placements: $placements
      userId: $userId
      vtexCookie: $vtexCookie
    ) {
      placements {
        creatives {
          URL_IMAGEM
          URL_IMAGEM_MOBILE
          URL_CLICK
          TEXT
          TEXTP
        }
      }
    }
  }
`

interface RichBannersProps {
  placements: string
}

/**
 * Hook de placemments da rich
 */
export const useRichBanners = ({ placements }: RichBannersProps) => {
  const { person } = useSession()
  const [cookies] = useCookies(['VtexRCMacIdv7'])
  const vtexCookie = cookies.VtexRCMacIdv7
  const userId = person?.email ? HashRich(person?.email) : ''

  const infoVariables = {
    placements,
    userId,
    vtexCookie,
  }

  const { data } = useQuery<RichBannersQueryQuery, RichBannersProps>(
    query,
    infoVariables,
    {
      revalidateOnReconnect: true,
    }
  )

  return data
}
