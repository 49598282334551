interface BannerLoaderProps {
  fullbanner: boolean
}

export const BannerLoader = (props: BannerLoaderProps) => {
  const { fullbanner } = props

  return (
    <>
      {fullbanner ? (
        <section className="animate-pulse container">
          <div className="w-full h-[190px] xs:h-[222px] sm:h-[252px] md:h-[456px] lg:h-[297px] xl:h-[371px] bg-slate-300" />
        </section>
      ) : (
        <section className="animate-pulse container flex flex-row flex-wrap px-[6px] lg:px-0">
          <div className="w-[calc(50%-12px)] h-[237px] md:h-[436px] mx-[6px] mb-2 last:w-full md:w-[calc(25%-12px)] lg:w-1/5 lg:last:w-1/5 lg:mx-0">
            <div className="w-full h-full lg:mx-1 lg:w-[calc(100%-8px)] bg-slate-300" />
          </div>
          <div className="w-[calc(50%-12px)] h-[237px] md:h-[436px] mx-[6px] mb-2 last:w-full md:w-[calc(25%-12px)] lg:w-1/5 lg:last:w-1/5 lg:mx-0">
            <div className="w-full h-full lg:mx-1 lg:w-[calc(100%-8px)] bg-slate-300" />
          </div>
          <div className="w-[calc(50%-12px)] h-[237px] md:h-[436px] mx-[6px] mb-2 last:w-full md:w-[calc(25%-12px)] lg:w-1/5 lg:last:w-1/5 lg:mx-0">
            <div className="w-full h-full lg:mx-1 lg:w-[calc(100%-8px)] bg-slate-300" />
          </div>
          <div className="w-[calc(50%-12px)] h-[237px] md:h-[436px] mx-[6px] mb-2 last:w-full md:w-[calc(25%-12px)] lg:w-1/5 lg:last:w-1/5 lg:mx-0">
            <div className="w-full h-full lg:mx-1 lg:w-[calc(100%-8px)] bg-slate-300" />
          </div>
          <div className="w-[calc(50%-12px)] h-[237px] md:h-[436px] mx-[6px] mb-2 last:w-full md:w-[calc(25%-12px)] lg:w-1/5 lg:last:w-1/5 lg:mx-0">
            <div className="w-full h-full lg:mx-1 lg:w-[calc(100%-8px)] bg-slate-300" />
          </div>
        </section>
      )}
    </>
  )
}
