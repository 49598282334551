import { useRichBanners } from 'src/hooks/useRichBanners'

import { BannerWithCarrossel } from './BannerWithCarrosel'
import { BannerWithoutCarrossel } from './BannerWithoutCarrosel'

interface BannerCarrosselRichProps {
  dots: boolean
  slides: number
  placement: string
  index?: number
}

/**
 * Componente funcional que recebe o placement da rich, faz o riquest e monta o resultado
 * @param props
 * @returns
 */
export const BannerCarrosselRich = (props: BannerCarrosselRichProps) => {
  const dots = props.dots !== undefined

  const initialPlacement =
    props.placement === 'home_page.full_banner' ||
    props.placement === 'home_page.full_banner_rodape'

  let { placement } = props

  switch (placement) {
    case 'home_page.full_banner':
      placement =
        'home_page.fullbanner_fast_01|home_page.fullbanner_fast_02|home_page.fullbanner_fast_03|home_page.fullbanner_fast_04'
      break

    case 'home_page.midia_cards_takeover':
      placement =
        'home_page.midia_cards_takeover01|home_page.midia_cards_takeover02|home_page.midia_cards_takeover03|home_page.midia_cards_takeover04|home_page.midia_cards_takeover05'
      break

    case 'home_page.midia_cards_centro':
      placement =
        'home_page.midia_cards_centro_posicao01|home_page.midia_cards_centro_posicao02|home_page.midia_cards_centro_posicao03|home_page.midia_cards_centro_posicao04|home_page.midia_cards_centro_posicao05'
      break

    case 'home_page.midia_cards_footer':
      placement =
        'home_page.midia_cards_footer01|home_page.midia_cards_footer02|home_page.midia_cards_footer03|home_page.midia_cards_footer04|home_page.midia_cards_footer05'
      break

    default:
      break
  }

  const bannerInfo = useRichBanners({ placements: placement })
  const data: any = bannerInfo?.RichBanners?.placements ?? []

  return (
    <>
      {initialPlacement ? (
        <BannerWithCarrossel
          bannerInfo={data}
          dots={dots}
          slides={props.slides}
          initialPlacement={initialPlacement}
          index={props.index}
        />
      ) : (
        <BannerWithoutCarrossel
          bannerInfo={data}
          initialPlacement={initialPlacement}
          dots={false}
          slides={0}
          index={props.index}
        />
      )}
    </>
  )
}
