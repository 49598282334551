import styles from './mosaico-marca.module.scss'

interface PosicaoProps {
  desktop: string
  mobile?: string
  alt: string
  href: string
}

interface MosaicoMarcaProps {
  posicao1: PosicaoProps
  posicao2: PosicaoProps
  posicao3: PosicaoProps
  posicao4: PosicaoProps
}
export const MosaicoMarca = (props: MosaicoMarcaProps) => {
  const { posicao1, posicao2, posicao3, posicao4 } = props

  return (
    <section className={styles.mosaicoMarca}>
      <div className={styles.container_mosaico1}>
        <div className={styles.first_image_mosaico}>
          <a
            href={posicao1.href ?? '#'}
            aria-label={posicao1.alt ?? 'link 1 mosaico'}
          >
            <picture>
              <source media="(max-width: 1024px)" srcSet={posicao1.mobile} />
              <source
                media="(min-width: 1025px)"
                srcSet={posicao1.desktop}
                width={603}
                height={335}
              />
              <img
                src={posicao1.mobile}
                alt={posicao1.alt ?? 'imagemMosaico1'}
                data-testid="imagemMosaico1"
                width={690}
                height={400}
                loading="lazy"
              />
            </picture>
          </a>
        </div>
        <div>
          <a
            href={posicao2.href ?? '#'}
            aria-label={posicao2.alt ?? 'link 2 mosaico'}
          >
            <picture>
              <source media="(max-width: 1024px)" srcSet={posicao2.mobile} />
              <source
                media="(min-width: 1025px)"
                srcSet={posicao2.desktop}
                width={286}
                height={335}
              />
              <img
                src={posicao2.mobile}
                alt={posicao2.alt ?? 'imagemMosaico2'}
                data-testid="imagemMosaico2"
                width={335}
                height={400}
                loading="lazy"
              />
            </picture>
          </a>
        </div>
      </div>
      <div className={styles.container_mosaico2}>
        <div className={styles.first_image_mosaico2}>
          <a
            href={posicao3.href ?? '#'}
            aria-label={posicao3.alt ?? 'link 3 mosaico'}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={posicao3.mobile} />
              <source
                media="(min-width: 769px)"
                srcSet={posicao3.desktop}
                width={446}
                height={200}
              />
              <img
                src={posicao3.mobile}
                alt={posicao3.alt ?? 'imagemMosaico3'}
                data-testid="imagemMosaico3"
                width={954}
                height={386}
                loading="lazy"
                className={styles.image}
              />
            </picture>
          </a>
        </div>
        <div>
          <a
            href={posicao4.href ?? '#'}
            aria-label={posicao4.alt ?? 'link 4 mosaico'}
          >
            <picture>
              <source media="(max-width: 1024px)" srcSet={posicao4.mobile} />
              <source
                media="(min-width: 1023px)"
                srcSet={posicao4.desktop}
                width={446}
                height={200}
              />
              <img
                src={posicao4.mobile}
                alt={posicao4.alt ?? 'imagemMosaico4'}
                data-testid="imagemMosaico4"
                width={954}
                height={386}
                className={styles.image}
                loading="lazy"
              />
            </picture>
          </a>
        </div>
      </div>
    </section>
  )
}
