import { useRef } from 'react'

export interface BannerCmsProps {
  desktop?: string
  mobile?: string
  alt?: string
  href?: string
}

export interface CmsBannerProps {
  banner?: BannerCmsProps
}

export const CmsBanner = ({ banner }: CmsBannerProps) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref} className="w-full">
      <a href={banner?.href} className="block">
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={banner?.mobile}
            width={600}
            height={300}
          />
          <source
            media="(min-width: 769px)"
            srcSet={banner?.desktop}
            width={955}
            height={290}
          />
          <img
            src={banner?.mobile}
            alt={banner?.alt ?? 'banner'}
            width={600}
            height={300}
            className="w-full h-full"
            loading="lazy"
          />
        </picture>
      </a>
    </div>
  )
}
