import { Carrossel } from 'src/components/common/Carrossel'
import { Image } from '../../ui/Image'
import Link from 'next/link'

interface BannerProps {
  desktop: string
  mobile: string
  heightDesktop: number
  heightMobile: number
  widthDesktop: number
  widthMobile: number
  href: string
}

interface BannerCarrosselVtexProps {
  alt: string
  dots?: boolean
  slides: number
  allItems: BannerProps[]
  index?: number
}

export const BannerCarrosselVtex = (props: BannerCarrosselVtexProps) => {
  const { alt, dots, slides, allItems, index } = props

  return (
    <Carrossel
      dots={dots}
      autoplay
      autoplaySpeed={300}
      slidesToShow={slides}
      classname="bannerVtex mb-2"
    >
      {allItems.map((item, key) => {
        // index é a a posição do componente na página
        // isEager vê se o componente está bem no inicio da pagina
        // e o 'key' (index do loop) é o primeiro, para tornar lazy ou não a ezibição da imagem
        const isEager = !!(index !== undefined && index <= 1 && key === 0)

        return (
          <div data-testid="divBannerVtex" key={key}>
            <Link href={item.href ?? ''} data-testid="linkBanner" tabIndex={-1}>
              <a>
                <Image
                  preload
                  alt={alt}
                  src={item.desktop}
                  srcSet={`${item.mobile} 300w, ${item.desktop} 1000w`}
                  width={1280}
                  height={360 / 1}
                  sizes="(max-width: 768px) 25vw, 30vw"
                  className="h-full"
                  data-testid="imgBanner"
                  data-fs-image
                  fetchpriority="high"
                  loading={isEager ? 'eager' : 'lazy'}
                />
              </a>
            </Link>
          </div>
        )
      })}
    </Carrossel>
  )
}
