import { useState } from 'react'

import { ModalBox } from 'src/components/molecule/ModalBox'
import { getStorageTokenOptin } from 'src/utils'
import { setEmailOptin } from 'src/utils/Optin'
import styles from './newsletter.module.scss'

interface NewsLetterProps {
  titulo: string
  regra: string
}

export const NewsLetterEpoca = (props: NewsLetterProps) => {
  const [email, setEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  // estados para mostrar respostas (cadastrou ou não)
  const [showFeedBack, setShowFeedBack] = useState(0)
  const [resp, setResp] = useState<any>(null)

  getStorageTokenOptin('_optintkn')

  const ApplyReturn = (respEmail: boolean) => {
    if (respEmail) {
      // email novo
      setResp(
        <>
          Oba! Você receberá um e-mail {''}
          <span className="text-pink">já já</span>
        </>
      )
      setShowFeedBack(1)
    } else {
      // já existe
      setResp(
        <>
          OBA! Você já é cadastrado aqui {''}
          <span className="text-pink">:)</span>
        </>
      )
      setShowFeedBack(1)
    }
  }

  const emailOptin = () => {
    setEmailOptin(email, String(localStorage.getItem('_optintkn'))).then(
      (response) => {
        if (
          response[0] === 500 &&
          response[1] === 'O email informado já está cadastrado.'
        ) {
          ApplyReturn(false)
        } else {
          ApplyReturn(true)
        }
      }
    )
  }

  const submitToAllin = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()

    emailOptin()
  }

  const tituloAjustado = () => {
    const size = props.titulo.indexOf(' ')
    const texto = props.titulo.slice(0, size)

    return (
      <>
        <p className="lg:pl-[130px] lg:pr-5 lg:leading-6 xl:pl-[150px] xl:pr-[65px]">
          <span className="text-pink">{texto}</span>{' '}
          {props.titulo.replace(texto, '')}
        </p>
      </>
    )
  }

  const showForm = showFeedBack ? `hidden` : ``
  const showResponse = showFeedBack ? `block` : `hidden`

  return (
    <section
      className={`container ${styles.newsletter_epoca} my-6 lg:flex xl:mt-[58px]`}
    >
      <div
        className={`${showForm} ${styles.titulo} w-full text-center px-6 text-[5vw] mb-2 md:text-[3vw] md:px-[210px] lg:px-0 lg:text-[22px] xl:text-[25px]`}
      >
        {tituloAjustado()}
      </div>
      <div
        className={`${showForm} w-full px-[15px] lg:px-0 lg:pr-[90px] xl:pr-[150px]`}
      >
        <form action="#">
          <input
            className="w-[65%] h-6 border-solid border-[1px] border-black rounded-l pl-1"
            type="email"
            name="email"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="bg-black text-white h-6 w-[35%] md:text-base text-sm rounded-r font-semibold"
            onClick={submitToAllin}
          >
            CADASTRAR
          </button>
        </form>
        <div>
          <p className="text-[11px] text-[#666666]">
            <a
              className=" underline"
              href="#!"
              onClick={(e) => {
                e.preventDefault()
                setModalOpen(true)
              }}
              title="Regras"
            >
              Clique aqui
            </a>{' '}
            e confira as regras
          </p>
          <ModalBox
            opened={modalOpen}
            toggle={setModalOpen}
            content={props.regra}
          />
        </div>
      </div>
      <div className={`${showResponse} text-center w-full text-2xl`}>
        {resp}
      </div>
    </section>
  )
}
