import { draftjsToHtml } from 'src/utils'
import { Image } from 'src/components/ui/Image'

import styles from './text-call-to-action.module.scss'
import { useState } from 'react'

export interface TextCallToActionProps {
  /**
   * Posição/Ordem do componente na página, é o index que é passado no loop do `RenderPageSections`
   */
  index: number
  /**
   * Diz se é para exibir ou não o componente
   */
  active: string
  /**
   * Subtitulo do componente
   */
  subtitulo: {
    content: string
  }
  /**
   * Titulo da seção
   */
  titulo: string
  /**
   * Target do Botão [ _blank | _self ]
   */
  target: string
  /**
   * Define o alinamento do titulo (left, right, center)
   */
  alinhamento_title: string
  /**
   * Define o alinamento do subtitulo (left, right, center)
   */
  alinhamento_subtitulo: string
  /**
   * Texto do botão call to action
   */
  cta_text: string
  /**
   * Link do botão call to action
   */
  cta_link: string
  /**
   * Ícone do botão call to action
   */
  cta_icone: string
  /**
   * Define a posição do ícone em relação ao texto do CTA (left, right)
   */
  cta_icone_posicao: string
  /**
   * Cor do texto do botão call to action
   */
  cta_color: string
  /**
   * Background do botão call to action quando passar o mouse em cima
   */
  cta_bg_hover: string
  /**
   * Background do botão call to action
   */
  cta_bg: string
}
/**
 *
 * Componente que exibe Titulo, subtitulo e Botão CTA
 */
export const TextCallToAction = ({
  index,
  active,
  subtitulo,
  alinhamento_subtitulo,
  titulo,
  alinhamento_title,
  cta_text,
  cta_link,
  cta_icone,
  cta_icone_posicao,
  cta_color,
  cta_bg_hover,
  cta_bg,
  target,
}: TextCallToActionProps) => {
  if (!active) {
    return <></>
  }

  const [isHover, setIsHover] = useState(false)
  const textoDescricao = draftjsToHtml(subtitulo.content)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <section className={styles.TextCallToAction} data-position={index}>
      <div className="container">
        {titulo && <h3 data-align-title={alinhamento_title}>{titulo}</h3>}

        {subtitulo.content && (
          <div
            data-align-description={alinhamento_subtitulo}
            dangerouslySetInnerHTML={{ __html: textoDescricao }}
          />
        )}

        {cta_link && cta_text && (
          <div className={styles.contentCta} data-align={alinhamento_subtitulo}>
            <a
              href={cta_link}
              target={target}
              style={{
                color: cta_color,
                backgroundColor: isHover ? cta_bg_hover : cta_bg,
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {cta_icone && (
                <div data-content-icon data-icon-position={cta_icone_posicao}>
                  <Image
                    preload
                    alt="icone_cta"
                    src={cta_icone}
                    width={32}
                    height={32}
                    fetchpriority="high"
                    loading="lazy"
                  />
                </div>
              )}
              <span>{cta_text}</span>
            </a>
          </div>
        )}
      </div>
    </section>
  )
}
