import styles from './image-single.module.scss'

interface ImagemSingleProps {
  index: number
  imagemDesk: string
  imagemMobile: string
  link: string
  alt: string
  target: string
}

export const ImagemSingle = ({
  index,
  imagemDesk,
  imagemMobile,
  link,
  alt,
  target,
}: ImagemSingleProps) => {
  if (!imagemDesk || !imagemMobile) {
    return <></>
  }

  return (
    <div data-index={index} className={`container ${styles.ImagemSingle}`}>
      {link ? (
        <a href={link} target={target ?? '_blank'}>
          <picture>
            <source media="(max-width: 768px)" srcSet={imagemMobile} />
            <source media="(min-width: 769px)" srcSet={imagemDesk} />
            <img src={imagemMobile} alt={alt ?? ''} loading="lazy" />
          </picture>
        </a>
      ) : (
        <picture>
          <source media="(max-width: 768px)" srcSet={imagemMobile} />
          <source media="(min-width: 769px)" srcSet={imagemDesk} />
          <img src={imagemMobile} alt={alt ?? ''} loading="lazy" />
        </picture>
      )}
    </div>
  )
}
