import { Carrossel } from 'src/components/common/Carrossel'

import { BannerRichItem } from './BannerRichItem'

interface BannerWithCarrosselProps {
  dots: boolean
  slides: number
  bannerInfo?: any
  initialPlacement: boolean
  index?: number
}

export const BannerWithCarrossel = ({
  dots,
  slides,
  bannerInfo,
  initialPlacement,
  index,
}: BannerWithCarrosselProps) => {
  return (
    <Carrossel
      dots={dots}
      autoplay
      autoplaySpeed={300}
      slidesToShow={slides}
      classname="bannerRich mb-2"
    >
      {bannerInfo?.map((item: any, key: number) => {
        const {
          creatives: [creatives],
        } = item

        // diz se a imagem vai carregar como 'eager' ou 'lazy'
        const isEager = !!(index !== undefined && index <= 1 && key === 0)

        return (
          <BannerRichItem
            key={key}
            placement={item?.placement}
            urlClick={creatives?.URL_CLICK}
            textoAlt={creatives?.TEXT}
            imgDesk={creatives?.URL_IMAGEM}
            imgMobile={creatives?.URL_IMAGEM_MOBILE}
            fullBanner={initialPlacement}
            aspectRatio={0}
            eager={isEager}
          />
        )
      })}
    </Carrossel>
  )
}
