import { useSearch } from '@faststore/sdk'
import { NextSeo } from 'next-seo'
import { lazy, Suspense } from 'react'
import type { MouseEvent } from 'react'

import Filter from 'src/components/search/Filter'
import Sort from 'src/components/search/Sort'
import FilterSkeleton from 'src/components/skeletons/FilterSkeleton'
import ProductGridSkeleton from 'src/components/skeletons/ProductGridSkeleton'
import Skeleton from 'src/components/skeletons/Skeleton'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import { mark } from 'src/sdk/tests/mark'
import { useUI } from 'src/sdk/ui/Provider'

import Section from '../Section'
import EmptyGallery from './EmptyGallery'
import styles from './product-gallery.module.scss'
import { useDelayedFacets } from './useDelayedFacets'
import { useDelayedPagination } from './useDelayedPagination'
import { useGalleryQuery } from './useGalleryQuery'
import { useProductsPrefetch } from './usePageProducts'

const GalleryPage = lazy(() => import('./ProductGalleryPage'))
const GalleryPageSkeleton = <ProductGridSkeleton loading />

interface Props {
  title: string
  searchTerm?: string
  hideFilters?: boolean
  idList?: string
}

function ProductGallery({ title, searchTerm, hideFilters, idList }: Props) {
  const { openFilter } = useUI()
  const { pages, addNextPage, addPrevPage } = useSearch()

  const { data } = useGalleryQuery()
  const facets = useDelayedFacets(data)
  const totalCount = data?.search.products.pageInfo.totalCount ?? 0
  const { next, prev } = useDelayedPagination(totalCount)

  useProductsPrefetch(prev ? prev.cursor : null)
  useProductsPrefetch(next ? next.cursor : null)

  if (data && totalCount === 0) {
    return (
      <Section
        data-testid="product-gallery"
        className={`${styles.fsProductListing} layout__content`}
        data-fs-product-listing
      >
        <EmptyGallery />
      </Section>
    )
  }

  return (
    <Section
      data-testid="product-gallery"
      className={`${styles.fsProductListing} layout__content-full container`}
      data-fs-product-listing
    >
      {searchTerm && (
        <header data-fs-product-listing-search-term className="layout__content">
          <h1>
            Você buscou por: <span>{searchTerm}</span>
          </h1>
        </header>
      )}
      <div
        data-fs-product-listing-content-grid
        data-hide-filters={hideFilters}
        className="layout__content"
      >
        <div data-fs-product-listing-filters>
          <div
            data-fs-product-listing-results-count
            data-count={totalCount}
            data-hide-filters={hideFilters}
          >
            <Skeleton
              shimmer
              variant="text"
              loading={!data}
              data-fs-product-listing-results-count-skeleton
            >
              <h2 data-testid="total-product-count">
                <strong>
                  {new Intl.NumberFormat('pt-BR').format(totalCount)}
                </strong>{' '}
                encontrados
              </h2>
            </Skeleton>
          </div>
          {!hideFilters && (
            <FilterSkeleton loading={facets?.length === 0}>
              <Filter facets={facets} />
            </FilterSkeleton>
          )}
        </div>
        {!hideFilters && (
          <div data-fs-product-listing-sort>
            <Skeleton
              shimmer
              variant="text"
              loading={facets?.length === 0}
              data-fs-product-listing-sort-skeleton
            >
              <Sort />
            </Skeleton>

            <Skeleton
              shimmer
              variant="button"
              loading={facets?.length === 0}
              data-fs-product-listing-filter-button-skeleton
            >
              <Button
                variant="tertiary"
                data-testid="open-filter-button"
                icon={<Icon name="FadersHorizontal" width={16} height={16} />}
                iconPosition="left"
                aria-label="Open Filters"
                onClick={openFilter}
              >
                Filtros
              </Button>
            </Skeleton>
          </div>
        )}
        <div data-fs-product-listing-results>
          {/* Add link to previous page. This helps on SEO */}
          {prev !== false && (
            <div data-fs-product-listing-pagination="top">
              <NextSeo
                additionalLinkTags={[{ rel: 'prev', href: prev.link }]}
              />
              <a
                onClick={(e: MouseEvent<HTMLElement>) => {
                  e.currentTarget.blur()
                  e.preventDefault()
                  addPrevPage()
                }}
                href={prev.link}
                rel="prev"
              >
                <Icon name="ArrowLeft" width={16} height={16} weight="bold" />
                <span>Página anterior</span>
              </a>
            </div>
          )}
          {/* Renderiza todos os produtos */}
          {data ? (
            <Suspense fallback={GalleryPageSkeleton}>
              {pages.map((page) => (
                <GalleryPage
                  key={`gallery-page-${page}`}
                  showSponsoredProducts={false}
                  page={page}
                  title={title}
                  idList={idList}
                />
              ))}
            </Suspense>
          ) : (
            GalleryPageSkeleton
          )}
          {/* Add link to next page. This helps on SEO */}
          {next !== false && (
            <div data-fs-product-listing-pagination="bottom">
              <NextSeo
                additionalLinkTags={[{ rel: 'next', href: next.link }]}
              />
              <a
                data-testid="show-more"
                onClick={(e: MouseEvent<HTMLElement>) => {
                  e.currentTarget.blur()
                  e.preventDefault()
                  addNextPage()
                }}
                href={next.link}
                rel="next"
              >
                <span>Carregar mais produtos</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

ProductGallery.displayName = 'ProductGallery'
export default mark(ProductGallery)
