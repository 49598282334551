import { useRef } from 'react'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'
import { ProductItem } from 'src/components/molecule/ProductItem'
import { useGetReviews } from 'src/hooks/useGetReviews'
import type { RecommendedProducts } from 'src/typings/richRelevance'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { SanitizationProps } from '../Shelf/shelfRich'
import styles from './lancamento.module.scss'

interface PopularShelfProps {
  products: [RecommendedProducts]
}

export const PopularShelf = (props: PopularShelfProps) => {
  const { products } = props
  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)
  const productsIds: any[] = []
  const sanitization = (item: SanitizationProps) => {
    return {
      slug: item?.clickURL,
      name: item?.name,
      gtin: '',
      id: item?.id,
      brand: {
        brandName: item?.brand,
        name: item?.brand,
      },
      isVariantOf: {
        productGroupID: item?.id,
        name: item?.name,
      },
      image: [
        {
          url: item?.imageURL,
          alternateName: item?.name,
        },
      ],
      offers: {
        lowPrice: item?.salePriceRangeCents[0] / 100,
        offers: [
          {
            price: item?.salePriceRangeCents[0] / 100,
            listPrice: item?.priceRangeCents[0] / 100,
            quantity: 1,
          },
        ],
      },
    }
  }

  if (products) {
    products.map((product) => {
      productsIds.push(product.id)

      return []
    })
  }

  const reviews = useGetReviews(productsIds)

  return (
    <section
      className={`container mt-[10px] richShelf shelf ${styles.carrosselRich}`}
    >
      <div className={`carrossel ${styles.vitrinesCarrossel}`}>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={2}
          slidesPerGroup={2}
          loop
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          autoplay={false}
          breakpoints={{
            375: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 15,
            },
            1024: {
              spaceBetween: 30,
            },
            1280: {
              spaceBetween: 10,
            },
          }}
        >
          {products.map((item: any, key: any) => {
            const i = sanitization(item)
            const prodId = item.id

            return (
              <SwiperSlide key={key}>
                <ProductItem
                  key={key}
                  item={i}
                  skus={item?.attributes?.skusIds ?? null}
                  type="rich"
                  review={reviews[prodId]}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div ref={prevRef} className="carrossel_buttons prev">
          <CarrosselArrow direction="prev" />
        </div>
        <div ref={nextRef} className="carrossel_buttons next">
          <CarrosselArrow direction="next" />
        </div>
      </div>
    </section>
  )
}
