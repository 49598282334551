import type { PropsWithChildren } from 'react'
import { useRef, useState } from 'react'

import { Navigation, Pagination, A11y, Autoplay, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './categoria-carrossel.module.scss'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'

interface CarrosselProps {
  children: PropsWithChildren<any>
  classname?: string
}

export const Carrossel = (props: CarrosselProps) => {
  const [loadSlide, setLoadSlide] = useState(false)

  const { children, classname } = props

  const classesLoader = !loadSlide
    ? 'container md:min-h-[360px] sm:min-h-[250px] min-h-[200px] '
    : ''

  const hideLoad = () => {
    setTimeout(() => setLoadSlide(true), 100)
  }

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  return (
    <section
      className={`carrossel ${classesLoader} container md:mx-auto relative ${classname}`}
    >
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay, Controller]}
        spaceBetween={0}
        slidesPerView={3}
        slidesPerGroup={3}
        pagination={false}
        onInit={() => hideLoad()}
        autoplay={false}
        breakpoints={{
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          425: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: -130,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: -80,
          },
          912: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: -100,
          },
          1280: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 0,
          },
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
      >
        {children.map((child: any, index: any) => {
          return <SwiperSlide key={`slide_${index}`}>{child}</SwiperSlide>
        })}
      </Swiper>

      <div ref={prevRef} className={`${styles.arrow} carrossel_buttons prev`}>
        <CarrosselArrow direction="prev" />
      </div>
      <div ref={nextRef} className={` ${styles.arrow} carrossel_buttons next`}>
        <CarrosselArrow direction="next" />
      </div>

      {!loadSlide && (
        <div className="container h-full w-full animate-pulse bg-slate-200 absolute inset-0 z-[5]" />
      )}
    </section>
  )
}
