import type { PropsWithChildren } from 'react'
import styles from './pdp-section-content.module.scss'

interface PDPSectionContentProps {
  title: string
  overflowHidden?: boolean
}

export const PDPSectionContent = ({
  title,
  overflowHidden = true,
  children,
}: PropsWithChildren<PDPSectionContentProps>) => {
  return (
    <div
      className={styles.pdpSectionContent}
      data-overflow-hidden={overflowHidden}
    >
      <header>
        <h4 data-title-section>{title}</h4>
      </header>
      <div data-wrapper-content-section>
        <div data-content-section>{children}</div>
      </div>
    </div>
  )
}
